import styled from "styled-components";
import { md, lg } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:80px 0 0 0;
  ${md(`
    padding:50px 0 30px 0;
  `)} 
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 12px;
  }
  h2{
    font-size:30px;
    line-height:40px;
    color: #000;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
  }
  .whoWeInfo{
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: stretch;
    ${md(`
      flex-direction: column;
      display: initial;
    `)}
    &__image{
      img{
        width: 100%;
        max-height: 460px;
        min-height: 460px;
        object-fit: cover;
      }
    }
    &__content{
      display:flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin-left:40px;
    
      ${md(`
        margin:30px 0 0 0;
      `)}
      p{
        font-size:16px;
        font-weight:400;
        line-height:27px;
        ${lg(`
          font-size:14px;
          line-height:25px;
          margin-bottom: 20px;
        `)}
        ${md(`
          margin:0 0 30px 0;
        `)}
      }
      .ant-btn{
        margin:0 0 40px 0;
      }
      a{
        margin:0 0 40px 0;
      }
    }
    &__count{
      display:flex;
      .CountContent{
        width:100%;
        display:flex;
        align-items: center;
        flex-direction: column;
        ${md(`
            flex-direction: row;
            justify-content: space-between;
          `)}
        &__numbers{
          margin-bottom: 50px;
          ${lg(`
            margin:0 0 30px 0;
          `)}
          ${md(`
            margin:20px 0 0 0;
          `)}
          h3{
            font-size:56px;
            font-weight:400;
            ${lg(`
              font-size:35px;
            `)}
            ${md(`
              font-size:30px;
            `)}
          }
          span{
            font-size:16px;
            font-weight:400;
            ${md(`
              width: 80%;
              display: flex;
              font-size:14px;
            `)}
          }
        }
      }
    }
  }
`;

export const SectionStyle = styled.div`
  width: 100%;
`;


export const CoverStyle = styled.div`
  width:100%;
`;
