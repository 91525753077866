import React from "react";
import { withTheme } from "styled-components";

function Analytics(props) {
  const { theme: logoColor } = props;

  return (
    <span className="logo analyticsIcon">
<svg width="130" height="142" viewBox="0 0 130 142" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_759_1388)">
<path opacity="0.15" d="M65.1365 141V102.955M65.1365 141L99.6774 102.955L129 70.6579M65.1365 141L30.4479 102.955L1 70.6579M65.1365 102.955V1M65.1365 102.955L129 70.6579M65.1365 102.955L1 70.6579M129 70.6579L65.1365 1M1 70.6579L65.1365 1" stroke="white"/>
</g>
<defs>
<clipPath id="clip0_759_1388">
<rect width="130" height="142" fill="white"/>
</clipPath>
</defs>
</svg>



    </span>
  );
}

export default withTheme(Analytics);