import { WrapperStyle } from "./OurWorks.styles";
import { Container } from "../Container/Container";
import useMediaQuery from '../../Hooks/useMediaQuery';
import SelectedWorkedList from "../SelectedWorkedList/SelectedWorkedList";
import { Typography, Flex } from "antd";
import { ButtonStyle } from "../Button/Button.style";
import { Constants } from "../../db/Constants";
import { useState } from "react";
const { Title } = Typography;

const OurWorks = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";

  const itemOnPage = 10;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handelClick = () =>{
    const newIndex = currentIndex + itemOnPage;
    const nextIndex = newIndex >=Constants?.selectedWork?.workList.length ? 0 : newIndex;
    setCurrentIndex(nextIndex);
    window.scrollTo({top:0, behavior: 'smooth'});
  }
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <Title level={2}>Our Projects</Title>
        <SelectedWorkedList selectedList={Constants?.selectedWork?.workList.slice(currentIndex, currentIndex + itemOnPage)}/>
        <Flex justify={"flex-end"} align={"center"} className="width100">
          <ButtonStyle type="link" shape="round" size={'large'} onClick={handelClick}>{currentIndex === 30 ? 'Previous' : 'Next'}</ButtonStyle>
        </Flex>
      </Container>
    </WrapperStyle>
  );
};

export default OurWorks;
