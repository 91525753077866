import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:123px 0 0 0;
  background-color: #000;
  position:relative;
  ${md(`
    padding:90px 8px 0 8px;
  `)} 
  .analyticsIcon{
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .footerLogo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 175px;
    svg {
      display:block;
      max-width:170px;
      height:auto;
    }
    .tagline{
      font-size:8px;
      color: #ffffff;
      margin-top:5px;
      text-transform: uppercase;
    }
  }
  .footerMenu{
    list-style:none;
    margin: 88px 0 0 0;
    ${md(`
      margin: 60px 0 0 0;
    `)}
    li{
      margin:0 0 13px 0;
      a{
        color: #FFF;
        font-size: 46px;
        font-weight: 400;
        line-height: 50px;
        ${md(`
          font-size: 34px;
        `)}
        &:hover{
          color:#14d675;
          transition: all 0.5s ease;
        }
      }
    }
  }
  .followUsWrapper{
    height:380px;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    ${md(`
      height:auto;
    `)}

    .followUs{
      h2{
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'Urbanist', sans-serif;
        margin-top: 155px;
        ${md(`
          margin: 50px 0 0 0;
        `)}
      }
      ul{
        list-style:none;
        display:flex;
        align-items: center;
        margin:43px 0 0 0;
        ${md(`
          margin:25px 0 0 0;
        `)}
        li{
          margin:0 10px 0 0;
          a{
            width: 38px;
            height: 38px;
            background-color: rgba(0, 0, 0, 0);
            border: 1px solid rgba(255, 255, 255, .2);
            border-radius: 50%;
            display:flex;
            justify-content: center;
            align-items: center;
            transition: background-color .3s, border-color
          }
        }
      }
    }
    .followAddress{
      p{
        color: #FFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; 
        width:200px;
        ${md(`
          width:100%;
          margin-top:20px;
        `)}
      }
    }
  }
  .contactInfoWrapper{
    .contactInfo{
      h2{
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'Urbanist', sans-serif;
        margin:155px 0 43px 0;
        ${md(`
          margin:60px 0 15px 0;
        `)}
      }
      ul{
        list-style:none;
        li{
          width:200px;
          a{
            color: #FFF;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; 
            &:hover{
              color:#14d675;
              transition: all 0.5s ease;
            }
          }
        }
      }
    }
  }
  .footerCopyright{
    width:100%;
    border-top: 1px solid #1A1A1A;
    padding: 25px 0;
    margin:100px 0 0 0;
    ${md(`
      margin:50px 0 15px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `)}
    .copyInfo{
      color: #999;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      ${md(`
        margin:0 0 15px 0;
      `)}
    }
    .copyMenu{
      list-style:none;
      display:flex;
      position:relative;
      li{
        margin: 0 0 0 25px;
        &:last-child::before{
          content: '.';
          font-size: 30px;
          color: #999;
          position: absolute;
          bottom: 0;
          margin-left:-15px;
        }
        a{
          color: #999;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          &:hover{
            color:#14d675;
            transition: all 0.5s ease;
          }
        }
      }
    }
  }
`;

export const SectionStyle = styled.div`
  width: 100%;
`;
