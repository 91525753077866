
import { Flex, Typography } from "antd";
import { AboutListSec } from "./WebDevelopment.styles";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import { ButtonStyle } from "../../Component/Button/Button.style";
const { Title, Paragraph, Text } = Typography;

function WebDevelopment() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Welcome to Grratz Web Development Services</Title>
          <div className="serviceContenet">
          <p className="content">At Grratz, we're passionate about crafting exceptional websites that not only meet but exceed our clients' expectations. With a dedicated team of skilled developers, designers, and digital strategists, we're committed to delivering customized web solutions that drive results and elevate your online presence. Whether you're a small business looking to establish your brand or a large corporation seeking to optimize your digital strategy, we're here to help you achieve your goals and stand out in today's competitive landscape.</p>
          <div className="list">
          <Title level={3}>Our Services:</Title>
            <ul>
            <Title level={5}>Custom Website Development:</Title>
              <li>We specialize in building custom websites tailored to your unique needs and objectives. From simple brochure sites to complex e-commerce platforms, we have the expertise to bring your vision to life.</li>
              <Title level={5}>Responsive Design:</Title>
              <li>In today's mobile-first world, it's crucial that your website looks and performs flawlessly across all devices. Our responsive design approach ensures that your site adapts seamlessly to desktops, tablets, and smartphones, providing an optimal user experience for all visitors.</li>
              <Title level={5}>Content Management Systems (CMS):</Title>
              <li>We work with leading CMS platforms such as WordPress, Drupal, and Joomla to empower you with easy-to-use tools for managing and updating your website content, keeping your site fresh and relevant.</li>
              <Title level={5}>E-commerce Solutions:</Title>
              <li>Our e-commerce expertise enables us to create robust online stores that drive sales and maximize conversions. Whether you're selling physical products, digital downloads, or subscription services, we have the know-how to build a scalable and secure e-commerce solution that meets your business needs.</li>
              <Title level={5}>SEO Optimization:</Title>
              <li>We understand the importance of visibility in today's crowded online marketplace. Our SEO experts will optimize your website for search engines, helping you rank higher in search results and attract more organic traffic to your site.</li>
            </ul>
          </div>

          <div className="list">
          <Title level={3}>Why Choose Grratz?</Title>
            <ul>
              <Title level={5}>Experience and Expertise:</Title>
              <li>With years of experience in the industry, our team brings a wealth of knowledge and expertise to every project, ensuring that your website is built to the highest standards of quality and performance.</li>
              <Title level={5}>Collaborative Approach:</Title>
              <li>We believe in collaboration and transparency every step of the way. From initial concept to final launch, we'll work closely with you to understand your goals, address your concerns, and deliver a solution that exceeds your expectations.</li>
              <Title level={5}>Customized Solutions:</Title>
              <li>We understand that every business is unique. That's why we take a personalized approach to each project, tailoring our services to suit your specific requirements, budget, and timeline.</li>
              <Title level={5}>Dedicated Support:</Title>
              <li>Our commitment to client satisfaction doesn't end with the launch of your website. We provide ongoing support and maintenance to ensure that your site remains secure, up-to-date, and performing at its best.</li>
            </ul>
          </div>

          <div className="titleText">
            <Title level={2}>Get Started Today!</Title>
            <p>Ready to take your online presence to the next level? Contact us today to schedule a consultation and discover how Grratz can help you achieve your web development goals. Let's work together to create a website that wows your audience and drives your business forward</p>
          </div>
           <Link to="mailto:hello@grratz.com" className="mailLink">hello@grratz.com</Link>
          <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" href="/contact" shape="round" size={'large'}>Contact Us</ButtonStyle>
            </Flex>
          </div>
          <Faq/>
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default WebDevelopment;
