import styled from "styled-components";
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  height: 100vh;
  display:flex;
  align-items: center;
  justify-content: space-between;
  ${lg(`
    height:auto;
  `)} 
  h2.ant-typography{
    font-family: Urbanist, sans-serif;
    font-size: 48px;
    font-weight: 400;
    color:#000;
    margin:30px 0 50px 0;
    ${md(`
      font-size: 35px;
       margin: 80px 0 20px 0;
  `)}
  }
  .aboutus{
    width:100%;
    ${md(`
      margin: 0 0 30px 0;
    `)}
    &__info{
      .ant-typography{
        margin-top:20px;
        font-size: 16px;
        font-weight: 400;
        line-height:30px;
      }
    }   
  }
`;


