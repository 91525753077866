import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const BlogListSec = styled.section`
`;
export const BlogListHead = styled.div`
    h1 {
        font-size: 38px;
        line-height: 44px;
        color: ${props => props.theme.BlackTextColor};
        margin: 0 0 40px;
        text-align: center;
        ${md(`
            font-size: 32px;
        `)}
    }
`;