
import { AboutListSec } from "./Services.styles";
import PageServices from "../../Component/Services/Services";
import WhatOurClientsSay from "../../Component/WhatOurClientsSay/WhatOurClientsSay";
import ReadyToCreate from "../../Component/ReadyToCreate/ReadyToCreate";
import Footer from "../../Component/Footer/Footer";
import { useEffect } from "react";

function Services() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <PageServices/>
      <WhatOurClientsSay/>
      <ReadyToCreate/>
      <Footer/>
    </AboutListSec>
  );
}

export default Services;
