import { Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import styled from "styled-components";
import { hexToRGBA } from "../../ThemeConfig/Variables";
import { md, lg } from "../../ThemeConfig/Variables";

export const HeaderStyle = styled(Header)`  
  &.ant-layout-header {
    //position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 15px 40px;
    transition: all 0.5s ease;
    background-color: ${(props) => props.theme.Secondary};
    display: flex;
    align-items:center;
    ${lg(`
      padding: 15px;
    `)}
    ${md(`
      padding: 10px 40px 10px 20px;
    `)}
    .ant-menu{
      width:60%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.Secondary};
      ${lg(`
      width:53%;
    `)}
    }
    &.responsiveLayout {
      width: 100%;
      position: relative;
      .ant-menu-horizontal{
        display: none;
      }
      .ant-menu-vertical{
        display:initial;
        position: absolute;
        left: 0;
        top: 62px;
        margin: 0;
        box-shadow: 0px 3px 5px 0px #c1c1c1;
        border-top: 1px solid #ccc;
        border-radius: 0 0 5px 5px;
        padding: 10px;
        .ant-menu-item-active{
          color: #ffffff;
        }
      }
      .hamburgerMenu{
        position: absolute;
        right: 15px;
        top: 5px;
      }
    }
    &.webLayout{
      .ant-menu-vertical{
        display: none;
      }
    }
    &.scrolled{
      position: sticky;
      box-shadow: ${(props) => `0 6px 16px 0 ${hexToRGBA(`${props.theme.BlackBgColor}`, 0.08)}`};
      backdrop-filter: blur(10px);
      transition: all 25s ease-in-out; 
      ${md(`
        position:relative;
      `)}
    }
  }
  .logoBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 215px;
    ${md(`
      width: 165px;
    `)}
    svg {
      display:block;
      max-width:120px;
      height:auto;
    }
    .tagline{
      font-size:8px;
      color: #000000;
      line-height:0px;
      text-transform: uppercase;
      ${md(`
      font-size:6px;
    `)}
    }
  }
  
`;

export const MenuStyle = styled(Menu)`
  border: 0px;
  line-height: 30px;
  margin-left: 20px;
`;

export const HeaderRight = styled.div`
  margin-left: auto;
`;
export const HeaderInnerStyle = styled.div`
     display: flex;
    align-items:center; 
    justify-content: space-between;
`;
