// import styled, { keyframes } from "styled-components";
import styled from "styled-components";
import { lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:40px 0;
  .createInfo{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    ${xl(`
       justify-content: center;
    `)} 
    h3{
      font-size:28px;
      line-height:42px;
      font-weight:500;
      color:#000;
      font-family: 'Urbanist', sans-serif;
      ${lg(`
        font-size:24px;
        margin-bottom: 10px;
      `)} 
    }
    p{
      font-size:16px;
      line-height:27px;
      font-weight:400;
      color:#333;
      ${xl(`
       margin: 10px 0 30px 0;
      `)}
      ${lg(`
        margin-bottom: 25px;
      `)} 
    }
    img{
      ${xl(`
        position: absolute;
        bottom: 80px;
        left: 0;
      `)} 
      ${lg(`
        display:none;
      `)} 
    }
  }
  .createImage{
    position: relative;
    overflow: hidden;
    img{
      width: 100%;
      max-height: 400px;
      min-height: 400px;
      object-fit: cover;
    }
  }
`;

export const CoverStyle = styled.div`
  width:100%;
`;