import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { images } from "../../ThemeConfig/images";
import { WrapperStyle, CoverStyle } from "./ContactUs.styles";
import { Container } from "../Container/Container";
import { Col, Row, Flex, Form, Input, Button, message } from "antd";
import { ButtonStyle } from "../Button/Button.style";
import useMediaQuery from '../../Hooks/useMediaQuery';
import TextArea from "antd/es/input/TextArea";
const ContactUs = () => {
const isMobile = useMediaQuery('(max-width: 768px)');
const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";

const form = useRef();
  const [errors, setErrors] = useState({});

  const sendEmail = (e) => {
    e.preventDefault();

    // Check if form is valid before sending email
    if (form.current.checkValidity()) {
      emailjs
        .sendForm('service_emxf0xw', 'template_ikzolzc', form.current, {
          publicKey: 'DzACFAi7Lj7-ZCBJ-',
        })
        .then(
          (response) => {
            console.log('SUCCESS!', response);
            message.success('Email sent successfully!');
            form.current.reset();
          },
          (error) => {
            console.log('FAILED...', error.text);
            message.error('Please fill in all required fields');
          },
        );
    } else {
      // If form is invalid, display error messages
      setErrors({
        ...errors,
        ...Object.fromEntries(new FormData(form.current).entries())
      });
    }
  };

return (
<WrapperStyle className={layoutClass}>
   <Container>
      <CoverStyle>
         <h2>Contact us</h2>
         <Row gutter={[60, 60]}>
         <Flex wrap="wrap" justify={"space-between"} align={"center"} className="contactus">
            <Col xs={24} md={14} lg={14}>
            <div className="contactus__info">
                <form ref={form} onSubmit={sendEmail} className="contactForm">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={12}>
                      <div className="customFormFild">
                        <label className="formLabel">First Name</label>
                        <input className="formField" type="text" name="user_first" placeholder="Enter Your First Name" required />
                        {errors['user_first'] && <span className="error">{errors['user_first']}</span>}
                      </div>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <div className="customFormFild">
                        <label className="formLabel">Last Name</label>
                        <input className="formField" type="text" name="user_last" placeholder="Enter Your Last Name" required />
                        {errors['user_last'] && <span className="error">{errors['user_last']}</span>}
                      </div>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <div className="customFormFild">
                        <label className="formLabel">Email</label>
                        <input className="formField" type="email" name="user_email" placeholder="Enter Your Email" required />
                        {errors['user_email'] && <span className="error">{errors['user_email']}</span>}
                      </div>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <div className="customFormFild">
                        <label className="formLabel">Mobile</label>
                        <input className="formField" type="text" name="user_mobile" placeholder="Enter Your Mobile Number" required />
                        {errors['user_mobile'] && <span className="error">{errors['user_mobile']}</span>}
                      </div>
                    </Col>
                    <Col xs={24} md={12} lg={24}>
                      <div className="customFormFild">
                        <label className="formLabel">Mesage</label>
                        <input className="formField" type="text" name="message" placeholder="Enter Your Message" required />
                        {errors['message'] && <span className="error">{errors['message']}</span>}
                      </div>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <Flex align="ccenter" justify="flex-end">
                        <input type="submit" value="Send" className="contactBtn"/>
                      </Flex>
                    </Col>
                  </Row>
                </form>
            </div>
            </Col>
            <Col xs={24} md={10} lg={10}>
            <div className="contactus__img">
               <img alt="contactImg" src={images.contactus} className="imgResponsive"/>
            </div>
            </Col>
         </Flex>
         </Row>
      </CoverStyle>
   </Container>
</WrapperStyle>
);
};
export default ContactUs;