import React from "react";
import { withTheme } from "styled-components";

function Logos(props) {
  const { theme: logoColor } = props;

  return (
    <span className="logo">
      <svg width="93" height="24" viewBox="0 0 93 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="88" y="19" width="5" height="5" fill="#F85F2F"/>
        <path d="M0 23.5911V1.57274H7.84937C9.04247 1.57274 10.1205 1.87156 11.0833 2.4692C12.0566 3.06684 12.8259 3.86894 13.391 4.87549C13.9562 5.88204 14.2388 6.9882 14.2388 8.19397C14.2388 9.43119 13.9457 10.5531 13.3596 11.5596C12.784 12.5557 12.0095 13.3526 11.0362 13.9502C10.0629 14.5374 9.00061 14.8309 7.84937 14.8309H3.83049V23.5911H0ZM3.83049 10.9934H7.4412C7.98543 10.9934 8.48255 10.8571 8.93258 10.5845C9.38261 10.3119 9.73845 9.94495 10.0001 9.48362C10.2722 9.02228 10.4083 8.50852 10.4083 7.94233C10.4083 7.36566 10.2722 6.84666 10.0001 6.38532C9.73845 5.92399 9.38261 5.55701 8.93258 5.2844C8.48255 5.0118 7.98543 4.87549 7.4412 4.87549H3.83049V10.9934Z" fill="black"/>
        <path d="M16.6799 7.8637H20.5104V23.5911H16.6799V7.8637ZM18.6266 5.83486C18.0614 5.83486 17.5852 5.65138 17.198 5.2844C16.8107 4.90695 16.6171 4.43513 16.6171 3.86894C16.6171 3.31324 16.8107 2.84666 17.198 2.4692C17.5852 2.09174 18.0562 1.90302 18.6109 1.90302C19.1655 1.90302 19.6313 2.09174 20.008 2.4692C20.3953 2.84666 20.5889 3.31324 20.5889 3.86894C20.5889 4.43513 20.4005 4.90695 20.0237 5.2844C19.647 5.65138 19.1812 5.83486 18.6266 5.83486Z" fill="black"/>
        <path d="M22.9508 23.5911L28.6966 15.6959L22.9979 7.84797H27.7232L31.0514 12.4246L34.3952 7.84797H39.1205L33.4219 15.6959L39.1676 23.5911H34.4423L31.0514 18.9358L27.6761 23.5911H22.9508Z" fill="black"/>
        <path d="M42.313 23.5911V0H46.1435V23.5911H42.313Z" fill="black"/>
        <path d="M61.5086 7.8637H65.3391V23.5911H61.5086L61.336 21.6409C60.9069 22.3434 60.3365 22.9148 59.6248 23.3552C58.9236 23.7851 58.0916 24 57.1287 24C55.967 24 54.8785 23.7798 53.8634 23.3394C52.8482 22.8991 51.9533 22.291 51.1789 21.5151C50.4149 20.7392 49.8131 19.8427 49.3735 18.8257C48.9444 17.8087 48.7299 16.7182 48.7299 15.5544C48.7299 14.4325 48.934 13.3788 49.3421 12.3932C49.7608 11.4076 50.3416 10.5426 51.0847 9.79817C51.8278 9.05373 52.686 8.47182 53.6593 8.05243C54.6326 7.63303 55.6792 7.42333 56.799 7.42333C57.8351 7.42333 58.7614 7.654 59.5777 8.11534C60.4045 8.57667 61.1057 9.16383 61.6813 9.8768L61.5086 7.8637ZM57.0345 20.3041C57.8508 20.3041 58.573 20.0996 59.2009 19.6907C59.8289 19.2818 60.3208 18.7313 60.6766 18.0393C61.0325 17.3368 61.2104 16.5662 61.2104 15.7274C61.2104 14.8781 61.0325 14.1075 60.6766 13.4155C60.3208 12.713 59.8236 12.1573 59.1852 11.7484C58.5573 11.3394 57.8404 11.135 57.0345 11.135C56.2286 11.135 55.4908 11.3447 54.821 11.7641C54.1616 12.173 53.6331 12.7235 53.2354 13.4155C52.8482 14.1075 52.6546 14.8781 52.6546 15.7274C52.6546 16.5767 52.8534 17.3473 53.2511 18.0393C53.6488 18.7313 54.1773 19.2818 54.8367 19.6907C55.5065 20.0996 56.2391 20.3041 57.0345 20.3041Z" fill="black"/>
        <path d="M76.9111 7.42333C78.4391 7.42333 79.831 7.79555 81.0869 8.53997C82.3428 9.2844 83.3423 10.2857 84.0854 11.5439C84.8285 12.7916 85.2 14.1756 85.2 15.6959C85.2 16.8388 84.9855 17.9135 84.5564 18.9201C84.1273 19.9266 83.5307 20.8126 82.7667 21.578C82.0132 22.3329 81.134 22.9253 80.1293 23.3552C79.1351 23.7851 78.0623 24 76.9111 24C75.9273 24 75.0534 23.7798 74.2894 23.3394C73.5358 22.8886 72.9027 22.3067 72.3898 21.5937V23.5911H68.5593V0H72.3898V9.82962C72.9027 9.11664 73.5358 8.53997 74.2894 8.09961C75.0534 7.64876 75.9273 7.42333 76.9111 7.42333ZM76.864 20.2883C77.6803 20.2883 78.4182 20.0839 79.0775 19.675C79.7473 19.2556 80.2811 18.6999 80.6788 18.0079C81.0765 17.3159 81.2753 16.5452 81.2753 15.6959C81.2753 14.8571 81.0765 14.0917 80.6788 13.3997C80.2811 12.6972 79.7473 12.1415 79.0775 11.7326C78.4077 11.3237 77.6698 11.1193 76.864 11.1193C76.0686 11.1193 75.3569 11.329 74.7289 11.7484C74.101 12.1573 73.6091 12.713 73.2533 13.4155C72.8974 14.1075 72.7195 14.8676 72.7195 15.6959C72.7195 16.5452 72.8974 17.3211 73.2533 18.0236C73.6091 18.7156 74.101 19.2661 74.7289 19.675C75.3569 20.0839 76.0686 20.2883 76.864 20.2883Z" fill="black"/>
      </svg>
    </span>
  );
}

export default withTheme(Logos);