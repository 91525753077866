
import { Flex, Typography } from "antd";
import { AboutListSec } from "./WorksDescription.styles";
import Footer from "../../Component/Footer/Footer";;
import { useEffect } from "react";
import { images } from "../../ThemeConfig/images";
import { Container } from "../../Component/Container/Container";
import SelectedWorked from "../../Component/SelectedWorked/SelectedWorked";
import { ButtonStyle } from "../../Component/Button/Button.style";
const { Title, Paragraph, Text } = Typography;

function WorksDescription() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Citadel</Title>
        </Container>
        <img src={images.workBanner} alt='workImg' className="imgResponsive"/>
        <Container>
          <div className="contentLeft">
          <Title level={2}>Project Overview</Title>
          <p className="content">Sit vulputate aliquam cras aliquam Et, nibh congue consequat pellentesque Metus fermentum dui pellentesque morbi volutpat commodo viverra. Feugiat nulla non mattis mi Bibendum diam tempus ultrices ultricies viverra Feugiat placerat viverra tortor orci feugiat. Scelerisque dictum consequat egestas enim, at orci. Eu a posuere aliquam sed aliquam tortor non Morbi id massa interdum nam tellus Magna nunc interdum tellus neque auctor sagittis.</p>
          <div className="image">
            <img src={images.workOverview} alt='overviewImg' className="imgResponsive"/>
          </div>
          <p className="content">Sit vulputate aliquam cras aliquam Et, nibh congue consequat pellentesque Metus fermentum dui pellentesque morbi volutpat commodo viverra. Feugiat nulla non mattis mi Bibendum diam tempus ultrices ultricies viverra Feugiat placerat viverra tortor orci feugiat. Scelerisque dictum consequat egestas enim, at orci. Eu a posuere aliquam sed aliquam tortor non Morbi id massa interdum nam tellus Magna nunc interdum tellus neque auctor sagittis.</p>
          <div className="list">
            <Title level={2}>What we did</Title>
            <ul>
              <li>Sit vulputate aliquam cras aliquam. Et, nibh congue consequat pellentesque</li>
              <li>Vestibulum massa aliquet odio mauris duis amet. In sed dapibus porta est amet</li>
              <li>Urna augue arcu duis eget proin sed morbi. Purus sit nunc sodales aliquam</li>
              <li>Tortor tellus commodo volutpat sed vel enim. Mi justo, proin volutpat turpis a et</li>
              <li>Ac ultrices sed odio fames dolor in eu. Augue tellus ac gravida nunc, ultrices lectus</li>
              <li>Sit vestibulum nullam neque nullam. Turpis integer viverra ornare commodo pellentesque est</li>
            </ul>
          </div>
          <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" shape="round" size={'large'}>Visit Website</ButtonStyle>
            </Flex>
          </div>
          <SelectedWorked />
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default WorksDescription;
