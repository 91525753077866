import React from "react";

function LinkArrow() {
  return (
    <span className="anticon anticon-user">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9433 13.918L12.9431 5.02266L4.33689 5.02278M12.5645 5.40126L4.67971 13.2861" stroke="black" strokeMiterlimit="10" strokeLinecap="square"/>
      </svg>
    </span>
  );
}

export default LinkArrow;
