import { WrapperStyle, CoverStyle } from "./Services.styles";
import { Container } from "../Container/Container";
import useMediaQuery from '../../Hooks/useMediaQuery';
import OurServicesList from "../OurServicesList/OurServicesList";
import { Typography } from "antd";
import { Constants } from "../../db/Constants";
const { Title } = Typography;

const PageServices = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <Title level={2}>Our Services</Title>
        <CoverStyle>
        <OurServicesList serviceList={Constants?.ourServiceList?.serviceList}/>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default PageServices;
