import { WrapperStyle, CoverStyle } from "./Counts.styles";
import { Container } from "../Container/Container";
import { Row, Flex } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Constants } from "../../db/Constants";

const Counts = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
        <Row gutter={[16, 16]}>
          <Flex wrap="wrap" justify={"space-between"} align={"center"} className="statics">
            {Constants?.workList?.map((list, index) => (
              <div className="statics__count" key={index}>
                <h2>{list.count}</h2>
                <p>{list.dicription}</p>
              </div>
            ))}
          </Flex>
        </Row>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default Counts;
