import styled from "styled-components";
import { md, lg } from "../../ThemeConfig/Variables";

export const AboutListSec = styled.section`
    width: 100%;
    h2.ant-typography{
        font-family: Urbanist, sans-serif;
        font-size: 48px;
        font-weight: 400;
        color:#000;
        margin:60px 0 50px 0;
        ${md(`
          font-size: 30px;
          margin:30px 0;
      `)}
    }
    .tagLink{
        margin:100px 0 0 0;
        .ant-tag{
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            font-size:12px;
            font-weight:500;
            padding:6px 12px;
            line-height:16px;
            margin-bottom: 25px;
            ${md(`
            margin-bottom: 15px;
          `)}
        }
        h2{
            font-size:38px;
            line-height:40px;
            color: #000;
            font-weight:400;
            font-family: 'Urbanist', sans-serif;
            ${md(`
            font-size:28px;
            margin-bottom: 20px;
          `)}
        }
    }
    .articalContenet{
        width:65%;
        ${md(`
            width:100%;
        `)}
        ${lg(`
            width:100%;
        `)}
        margin: 40px 0 0 0;
        h2.ant-typography{
            margin:40px 0 0 0;  
        }
        h4.ant-typography{
            margin:25px 0 10px 0;  
        }
        .content{
            margin:0 0 18px 0;
            font-size: 16px;
            line-height:25px;
            font-weight: 400;
        }
        .list{
            .ant-typography{
                margin:0 0 25px 0;  
            }
            ul{
                margin: 0 0 0 30px;
                padding:0;
                li{
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
            }
        }
        .reality{
            margin:20px 0;
        }
        .titleText{
            h4.ant-typography{
                margin:50px 0 20px 0;  
            }
            p{
                margin:15px 0 18px 0;
                font-size: 16px;
                line-height:25px;
                font-weight: 400;
            }
           
        }
        .link{
            margin:40px 0 80px 0;
        }
        
        
    }
    .postTitleWrap {
        justify-content: space-between;
        align-items: center;
        display: flex;
        .postInfo{
            width: 100%;
            max-width: 200px;
            grid-row-gap: 12px;
            flex-direction: column;
            display: flex;
            .bodysSmall{
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }
            .postTime {
                border-top: 1px solid rgba(0, 0, 0, .1);
                padding-top: 12px;
            }
        }
    }
`