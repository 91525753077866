
import { Flex, Typography } from "antd";
import { AboutListSec } from "./AppDevelopment.styles";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import { ButtonStyle } from "../../Component/Button/Button.style";
const { Title } = Typography;

function AppDevelopment() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Welcome to Grratz App Development Services</Title>
          <div className="serviceContenet">
          <p className="content">At Grratz, we specialize in crafting innovative and user-friendly mobile applications tailored to meet the unique needs and objectives of our clients. With a team of experienced developers and designers, we bring your app ideas to life, from concept to launch and beyond. Whether you're a startup looking to disrupt the market or an established enterprise seeking to enhance your digital presence, we're here to help you succeed in the ever-evolving world of mobile technology.</p>
          <div className="list">
          <Title level={3}>Our Services:</Title>
            <ul>
            <Title level={5}>Custom App Development:</Title>
              <li>We work closely with you to understand your vision and objectives, crafting bespoke mobile applications that resonate with your target audience and drive business growth.</li>
              <Title level={5}>Platform Expertise:</Title>
              <li>Our team is proficient in developing apps for various platforms, including iOS, Android, and cross-platform solutions, ensuring seamless performance and user experience across devices.</li>
              <Title level={5}>UI/UX Design:</Title>
              <li>We prioritize user-centric design, creating intuitive interfaces and engaging experiences that captivate users and keep them coming back for more.</li>
              <Title level={5}>Quality Assurance:</Title>
              <li>Our rigorous testing processes ensure that your app meets the highest standards of quality, performance, and security before it goes live, minimizing risks and maximizing user satisfaction.</li>
              <Title level={5}>Maintenance and Support:</Title>
              <li>We provide ongoing maintenance and support services to ensure that your app remains up-to-date, secure, and optimized for performance, allowing you to focus on your core business objectives.</li>
            </ul>
          </div>

          <div className="list">
          <Title level={3}>Why Choose Grratz?</Title>
            <ul>
              <Title level={5}>Expertise and Experience:</Title>
              <li>Our team brings years of experience and expertise in app development, staying abreast of the latest technologies, trends, and best practices to deliver exceptional results.</li>
              <Title level={5}>Collaborative Approach:</Title>
              <li>We believe in close collaboration with our clients throughout the development process, keeping you informed and involved every step of the way to ensure that your vision is realized.</li>
              <Title level={5}>Client-Centric Solutions:</Title>
              <li>Your success is our priority. We take the time to understand your goals, challenges, and audience to deliver customized solutions that meet your unique requirements and exceed your expectations.</li>
              <Title level={5}>Affordable and Transparent Pricing:</Title>
              <li>We offer competitive and transparent pricing, with flexible packages tailored to suit businesses of all sizes and budgets, ensuring maximum value for your investment.</li>
            </ul>
          </div>

          <div className="titleText">
            <Title level={2}>Get Started Today!</Title>
            <p>Ready to transform your app ideas into reality? Get in touch with us today to schedule a consultation and discover how Grratz can help you achieve your app development goals. Let's embark on this journey together and create something extraordinary!</p>
          </div>
           <Link to="mailto:hello@grratz.com" className="mailLink">hello@grratz.com</Link>
            <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" href="/contact" shape="round" size={'large'}>Contact Us</ButtonStyle>
            </Flex>
          </div>
          <Faq/>
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default AppDevelopment;
