import React from "react";

function Youtube() {
  return (
    <span className="anticon anticon-user">
      <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0349 3.0493C15.0349 3.0493 14.8946 2.05594 14.4628 1.61977C13.9161 1.04571 13.3048 1.04289 13.0245 1.00912C11.017 0.862793 8.00285 0.862793 8.00285 0.862793H7.99724C7.99724 0.862793 4.98313 0.862793 2.9756 1.00912C2.69522 1.04289 2.08398 1.04571 1.53724 1.61977C1.10545 2.05594 0.968064 3.0493 0.968064 3.0493C0.968064 3.0493 0.822266 4.21712 0.822266 5.38213V6.47397C0.822266 7.63899 0.96526 8.80681 0.96526 8.80681C0.96526 8.80681 1.10545 9.80014 1.53444 10.2363C2.08118 10.8104 2.79896 10.7907 3.1186 10.8526C4.26816 10.9623 8.00004 10.9961 8.00004 10.9961C8.00004 10.9961 11.017 10.9905 13.0245 10.847C13.3048 10.8132 13.9161 10.8104 14.4628 10.2363C14.8946 9.80014 15.0349 8.80681 15.0349 8.80681C15.0349 8.80681 15.1778 7.6418 15.1778 6.47397V5.38213C15.1778 4.21712 15.0349 3.0493 15.0349 3.0493ZM6.51683 7.79938V3.74999L10.3945 5.78172L6.51683 7.79938Z" fill="white"/>
      </svg>
    </span>
  );
}

export default Youtube;
