import { WrapperStyle, CoverStyle } from "./MeetOurTeam.styles";
import { Container } from "../Container/Container";
import { Tag, Flex, Card } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Constants } from "../../db/Constants";
const { Meta } = Card;

const MeetOurTeam = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
        <Tag>{Constants?.meetOurTeam?.subTitle}</Tag>
        <h2>{Constants?.meetOurTeam?.title}</h2>
          <Flex justify={"space-between"} align={"center"}  gap={30} className="members">
            {Constants?.meetOurTeam?.memberList?.map((list, index) => (
              <Card
                key={index}
                bordered={false}
                className="members__info"
                cover={<img src={list.image} alt='membersImg' className="imgResponsive"/>}
              >
                <Meta title={list.title} description={list.designation} />
              </Card>
            ))}
          </Flex>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default MeetOurTeam;
