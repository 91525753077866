import { WrapperStyle, CoverStyle } from "./SelectedWorked.styles";
import { Container } from "../Container/Container";
import { Tag, Flex  } from "antd";
import { Link } from "react-router-dom";
import useMediaQuery from '../../Hooks/useMediaQuery';
import SelectedWorkedList from "../SelectedWorkedList/SelectedWorkedList";
import { Constants } from "../../db/Constants";
import { useState } from "react";

const SelectedWorked = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";

  const itemOnPage = 4;
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
        <Tag>{Constants?.selectedWork?.subTitle}</Tag>
        <h2>{Constants?.selectedWork?.title}</h2>
        <SelectedWorkedList selectedList={Constants?.selectedWork?.workList.slice(currentIndex, currentIndex + itemOnPage)}/>
        <Flex justify={"flex-end"} align={"center"} className="width100">
          <Link to={Constants?.selectedWork?.link} className="linkBtn">{Constants?.selectedWork?.linkText}</Link>
        </Flex>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default SelectedWorked;
