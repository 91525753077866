import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:100px 0 0 0;
  ${md(`
    padding:80px 0 30px 0;
  `)}
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 25px;
    ${md(`
    margin-bottom: 15px;
  `)}
  }
  h2{
    font-size:38px;
    line-height:40px;
    color: #000;
    font-weight:400;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
    ${md(`
    font-size:28px;
    margin-bottom: 20px;
  `)}
  }
  .howWork{
    width:100%;
    &__info{
      padding-left:50px;
      ${md(`
        padding: 25px 0 0 0;
      `)}
      .ant-typography {
        font-size: 16px;
        font-weight: 400;
        line-height:30px;
      }
      .listItem{
        list-style-image: url("../img/check.svg");
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        padding:0;
        margin:40px 0 40px 30px;
        ${md(`
          margin:20px 0 20px 30px;
        `)}
        li{
          width:48%;
          margin-bottom:15px;
          ${md(`
            width:100%;
          `)}
          span{
            position:relative;
            top:-6px;
          }
        }
      }
      .ant-btn-link{
        max-width:140px;
      }
    }
  }
  
`;
