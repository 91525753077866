
import { Flex, Typography } from "antd";
import { AboutListSec } from "./ConsultationStrategy.styles";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import { ButtonStyle } from "../../Component/Button/Button.style";
const { Title } = Typography;

function ConsultationStrategy() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Drive Your Business Forward with Expert Consultation and Strategic Guidance from Grratz</Title>
          <div className="serviceContenet">
          <p className="content">At Grratz, we understand that navigating the complexities of business can be challenging. That's why we offer tailored consultation and strategy services to help businesses like yours overcome obstacles, capitalize on opportunities, and achieve sustainable growth. With our team of seasoned experts and proven methodologies, we're committed to providing you with the insights, strategies, and support you need to thrive in today's competitive landscape.</p>
          <div className="list">
          <Title level={3}>Our Services:</Title>
            <ul>
            <Title level={5}>Business Consultation:</Title>
              <li>Our experienced consultants work closely with you to understand your unique challenges, goals, and aspirations. Through in-depth analysis and discussions, we provide strategic recommendations and actionable insights to drive your business forward.</li>
              <Title level={5}>Market Research and Analysis:</Title>
              <li>Gain valuable insights into your industry, competitors, and target market with our comprehensive market research and analysis services. We'll help you identify trends, opportunities, and threats to inform your strategic decision-making.</li>
              <Title level={5}>Strategic Planning:</Title>
              <li>Develop a clear roadmap for success with our strategic planning services. We'll work with you to define your vision, set measurable goals, and outline actionable strategies to achieve them, ensuring alignment across your organization.</li>
              <Title level={5}>Digital Transformation:</Title>
              <li>Embrace the digital age and unlock new opportunities for growth with our digital transformation services. Whether you're looking to improve efficiency, enhance customer experience, or drive innovation, we'll help you leverage technology to stay ahead of the curve.</li>
              <Title level={5}>Change Management:</Title>
              <li>Navigate organizational change effectively with our change management services. From mergers and acquisitions to process improvements and restructuring, we'll support you every step of the way, minimizing disruption and maximizing success.</li>
            </ul>
          </div>

          <div className="list">
          <Title level={3}>Why Choose Grratz?</Title>
            <ul>
              <Title level={5}>Expertise and Experience:</Title>
              <li>Our team brings decades of collective experience and expertise across various industries and disciplines, ensuring that you receive the highest quality advice and support.</li>
              <Title level={5}>Client-Centric Approach:</Title>
              <li>We prioritize your needs and objectives above all else. Our consultative approach is tailored to your specific requirements, delivering solutions that are practical, effective, and sustainable.</li>
              <Title level={5}>Results-Driven Solutions:</Title>
              <li>We're committed to delivering measurable results. Whether it's increased revenue, improved efficiency, or enhanced customer satisfaction, our strategies are designed to deliver tangible outcomes that drive business success.</li>
              <Title level={5}>Long-Term Partnership:</Title>
              <li>We're not just consultants – we're partners in your success. Our dedicated team provides ongoing support and guidance, helping you adapt to changing market conditions, overcome obstacles, and seize new opportunities as they arise.</li>
            </ul>
          </div>

          <div className="titleText">
            <Title level={2}>Get Started Today!</Title>
            <p>Ready to take your business to the next level? Contact us today to schedule a consultation and discover how Grratz can help you achieve your goals with our expert consultation and strategy services. Let's work together to drive your business forward and achieve lasting success</p>
          </div>
           <Link to="mailto:hello@grratz.com" className="mailLink">hello@grratz.com</Link>
          <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" href="/contact" shape="round" size={'large'}>Contact Us</ButtonStyle>
            </Flex>
          </div>
          <Faq/>
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default ConsultationStrategy;
