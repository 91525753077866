import { WrapperStyle, CoverStyle } from "./OurServices.styles";
import { Container } from "../Container/Container";
import { Tag, Flex  } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import OurServicesList from "../OurServicesList/OurServicesList";
import { Constants } from "../../db/Constants";
import { Link } from "react-router-dom";

const OurServices = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <Tag>{Constants?.ourServiceList?.subTitle}</Tag>
        <h2>{Constants?.ourServiceList?.title}</h2>
        <CoverStyle>
          <OurServicesList serviceList={Constants?.ourServiceList?.serviceList}/>
          <Flex justify={"flex-end"} align={"center"} className="width100">
            <Link to={Constants?.ourServiceList?.link} className="linkBtn" aria-label="Redirect to ourservice">{Constants?.ourServiceList?.linkText}</Link>
          </Flex>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};  

export default OurServices;
