import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const AboutListSec = styled.section`
    width: 100%;
    ${md(`
      font-size: 35px;
    `)}
    h2.ant-typography{
        font-family: Urbanist, sans-serif;
        font-size: 48px;
        font-weight: 400;
        color:#000;
        margin:60px 0 50px 0;
        ${md(`
            font-size: 35px;
            margin: 40px 0 35px 0;
        `)}
    }
`;