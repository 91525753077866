import styled from "styled-components";
import { md, lg } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:60px 0 130px 0;
  ${lg(`
    padding:50px 0 0 0;
  `)} 
  position:relative;
  .latestArticles{
    width:100%;
    .articleWrapper{
      &__title{
        .ant-tag{
          border-radius: 20px;
          border: 1px solid rgba(0, 0, 0, 0.20);
          font-size:12px;
          font-weight:500;
          padding:6px 12px;
          line-height:16px;
          margin-bottom: 12px;
        }
        h2{
          font-size:30px;
          line-height:40px;
          color: #000;
          font-family: 'Urbanist', sans-serif;
          margin-bottom: 40px;
        }
      }
      &__btn{
        margin-top:40px;
        max-width:140px;
        ${lg(`
          display: none;
        `)} 
      }
    }
    &__wrap{
      width: 100%;
      max-width: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ${lg(`
        max-width:100%;
      `)} 
      ${md(`
        display: inline;
      `)} 
      .ant-tag{
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        font-size:12px;
        font-weight:500;
        padding:6px 12px;
        line-height:16px;
        margin-bottom: 12px;
      }
      h2{
        font-size:30px;
        line-height:40px;
        color: #000;
        font-family: 'Urbanist', sans-serif;
        margin-bottom: 40px;
      }
      .articlesCard{
       margin-right:30px;
       ${lg(`
          display: flex;
          width: 48%;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 40px 0;
      `)} 
       ${md(`
        width: 100%;
        margin: 0 0 40px 0;
      `)} 
      .ant-card{
        border:none;
        background: transparent;
        .ant-card-body{
          padding:18px 0 0 0;
          .ant-card-meta{
            .ant-card-meta-detail{
              .ant-card-meta-title{
                font-size:14px;
                font-weight:400;
                color: #333333;
              }
              .ant-card-meta-description{
                p{
                  font-size:18px;
                  font-weight:500;
                  color: #333333;
                  font-family: 'Urbanist', sans-serif;
                }
                a{
                  margin-top:32px;
                  display: flex;
                  font-size:14px;
                  font-weight:500;
                  line-height:27px;
                  color: #333333;
                  .anticon{
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
        .ant-card-cover{
          overflow: hidden;
          img{
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
            transform-style: preserve-3d;
            transition: all 0.5s ease-in;
            border-radius:0px;
          }
        }
      }
        &:hover .ant-card-cover{
          img{
            transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1);
            transform-style: preserve-3d;
            transition: all 0.5s ease-out;
          }
        }
      }
    }
    &__post{
      width: 100%;
      height: 100%;
      max-width: 40%;
      position: absolute;
      top: 0;
      right: 0;
      ${lg(`
        max-width:100%;
        position: relative;
      `)} 
      ${md(`
        max-width: 100%;
        position: relative;
      `)} 
      .postImage{
        position:relative;
        height:100%;
        overflow: hidden;
        img{
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
            transform-style: preserve-3d;
            transition: all 0.5s ease-in;
        }
        &__info{
          position: absolute;
          bottom:10px;
          padding:35px;
          ${md(`
            padding:15px;
          `)} 
          span{
            font-size:14px;
            font-weight:400;
            color: #ffffff;
          }
          .imageList{
            width:100%;
            h2{
              font-size:22px;
              font-weight:500;
              line-height:32px;
              color: #ffffff;
              margin-top:12px;
              width:75%;
              ${md(`
                font-size:18px;
                line-height:26px;
                width:100%;
                margin-top:8px;
              `)} 
            }
            p{
              width: 45px;
              height: 45px;
              border-radius: 50%;
              background-color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              ${md(`
                display:none;
              `)} 
            }
          }
        }
        img{
          height: 100%;
          width:100%;
          object-fit: cover;
          ${md(`
            position: relative;
            bottom: -4px;
          `)} 
          
        }
        &:hover{
          img{
            transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1);
            transform-style: preserve-3d;
            transition: all 0.5s ease-out;
          }
        }
      }
    }
  }
`;

export const SectionStyle = styled.div`
  width: 100%;
`;
export const CoverStyle = styled.div`
  width:100%;
`;