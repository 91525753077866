import { WrapperStyle, CoverStyle } from "./BlogList.styles";
import { Container } from "../Container/Container";
import { Col, Row, Flex, Card  } from "antd";
import { ButtonStyle } from "../Button/Button.style";
import { Link } from "react-router-dom";
import { LinkArrow } from "../Icon";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Constants } from "../../db/Constants";
const { Meta } = Card;

const BlogList = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle>
      <Container>
        <CoverStyle>
            <Flex justify={"space-between"} align={"center"} className="latestArticles">
              <Row gutter={[32, 32]}>
                {Constants?.blogList.map((list, index) => (
                  <Col xs={24} md={8} lg={8} key={index}>
                    <Link to={list.linkTitle}>
                  <div className="latestArticles__card">
                    <Card
                      hoverable={false}
                      bordered={false}
                      cover={<img alt="ListImg" src={list.image} />}
                    >
                      <Meta title={<div>{list.date}</div>} description={<div><p>{list.title}</p><Link to={list.linkTitle}>{list.articalLink}<LinkArrow/></Link></div>} />
                    </Card>
                  </div>
                  </Link>
                </Col>
                ))}
              </Row>
            </Flex>
            <Flex justify={"flex-end"} align={"center"} className="width100">
              <ButtonStyle type="link" shape="round" size={'large'}>Next</ButtonStyle>
            </Flex>
          </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default BlogList;
