
import { Flex, Typography } from "antd";
import { AboutListSec } from "./UIDesigning.styles";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import { ButtonStyle } from "../../Component/Button/Button.style";
const { Title } = Typography;

function UIDesigning() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Elevate Your Digital Experience with Grratz UI/UX Design Services</Title>
          <div className="serviceContenet">
          <p className="content">At Grratz, we specialize in creating captivating and intuitive user interfaces (UI) and user experiences (UX) that delight users and drive business growth. With a focus on user-centered design principles and cutting-edge technology, we're dedicated to crafting digital experiences that engage, inspire, and leave a lasting impression. Whether you're launching a new product, redesigning an existing platform, or seeking to optimize your user journey, we're here to bring your vision to life and exceed your expectations.</p>
          <div className="list">
          <Title level={3}>Our Services:</Title>
            <ul>
            <Title level={5}>UI Design:</Title>
              <li>Our UI designers are experts in creating visually stunning interfaces that captivate users and reflect your brand identity. From wireframes and mockups to pixel-perfect designs, we'll ensure your digital products are not only beautiful but also functional and intuitive to use.</li>
              <Title level={5}>UX Design:</Title>
              <li>We take a user-centric approach to UX design, focusing on understanding your audience's needs, behaviors, and motivations. Through user research, personas, and journey mapping, we'll design seamless and delightful experiences that keep users engaged and coming back for more.</li>
              <Title level={5}>Prototyping and Testing:</Title>
              <li>Before bringing your designs to life, we'll create interactive prototypes and conduct usability testing to gather valuable feedback and validate design decisions. This iterative process ensures that your final product meets user expectations and business objectives</li>
              <Title level={5}>Responsive Design:</Title>
              <li>With the increasing prevalence of mobile and multi-device usage, we prioritize responsive design to ensure your digital experiences look and perform flawlessly across all screen sizes and devices. From smartphones and tablets to desktops and beyond, we'll optimize your designs for maximum impact and accessibility.</li>
              <Title level={5}>Accessibility and Inclusivity:</Title>
              <li>We believe that digital experiences should be inclusive and accessible to all users, regardless of their abilities. Our designers adhere to accessibility standards and best practices to ensure that everyone can interact with your products comfortably and confidently.</li>
            </ul>
          </div>

          <div className="list">
          <Title level={3}>Why Choose Grratz?</Title>
            <ul>
              <Title level={5}>Design Excellence:</Title>
              <li>With a keen eye for detail and a passion for innovation, our design team delivers world-class UI/UX solutions that stand out in today's competitive landscape.</li>
              <Title level={5}>Collaborative Approach:</Title>
              <li>We believe in close collaboration with our clients throughout the design process, from initial concepts to final delivery. Your feedback and input are invaluable to us, ensuring that we create designs that align with your vision and goals.</li>
              <Title level={5}>User-Centered Philosophy:</Title>
              <li>Our design decisions are driven by a deep understanding of your users' needs and preferences. By putting the user first, we create experiences that resonate with your audience and drive engagement and loyalty.</li>
              <Title level={5}>Technical Expertise:</Title>
              <li>Our designers are well-versed in the latest design tools and technologies, allowing us to push the boundaries of creativity and innovation while maintaining technical feasibility and scalability.</li>
            </ul>
          </div>

          <div className="titleText">
            <Title level={2}>Get Started Today!</Title>
            <p>Ready to elevate your digital experience? Contact us today to schedule a consultation and discover how Grratz can help you achieve your UI/UX design goals. Let's work together to create exceptional digital experiences that leave a lasting impression on your users and drive business success</p>
          </div>
           <Link to="mailto:hello@grratz.com" className="mailLink">hello@grratz.com</Link>
          <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" href="/contact" shape="round" size={'large'}>Contact Us</ButtonStyle>
            </Flex>
          </div>
          <Faq/>
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default UIDesigning;
