import { Link } from "react-router-dom";
import styled from "styled-components";
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  height: 100vh;
  display:flex;
  align-items: center;
  justify-content: space-between;
  ${xl(`
      height: 800px;
  `)}
  .ant-divider-horizontal{
    border-block-start: 1px solid #333333;
  }
  &.responsiveLayout{
    height: auto;
    padding: 20px 0;
    overflow: hidden;
  }
`;

export const BannerImgStyle = styled.div`
  width: 100%;
  margin-bottom:12px;
  position: relative;
    img {
      width: 100%;
      max-height: 370px;
      min-height: 370px;
      object-fit: cover;
    }
    .videoIcon{
      display:flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, .5);
      width: 50px;
      height: 50px;
      border: 1px solid rgba(255, 255, 255, .5);
      border-radius: 50%;
      transition: background-color .3s;
      position: absolute;
      bottom: 20px;
      right: 20px;
      &:hover{
        background-color: rgba(255, 255, 255, .1);
        cursor: pointer;
      }
    }
`;
export const BannerInfoStyle = styled.div`
  width: 100%;
  min-height:335px;
  max-height:335px;
  display:flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 95px;
  ${lg(`
      margin: 0 0 0 0;
      min-height:auto;
      max-height:auto;
  `)}
  ${md(`
      min-height:auto;
      max-height:auto;
      margin: 0 0 10px 0;
  `)}
  h1{
    font-size: 60px;
    line-height:80px;
    font-weight: 600;
    text-align: right;
    font-family: 'Urbanist', sans-serif;
    ${lg(`
      font-size: 50px;
    `)}
    ${md(`
      font-size: 30px;
      line-height:45px;
      text-align: center;
      margin-bottom: 1rem;
    `)}
  }
  .ant-btn-primary{
    float:right;
    margin-top:40px;
    ${lg(`
      padding:30px;
    `)}
  }
  .linkBtnBg{
    ${md(`
      margin-top: 0px;
      width:85%;
      margin: 0 auto;
    `)}
  }
`;

export const SectionStyle = styled.div`
  width: 100%;
  .ant-avatar{
    border-color: #333333;
    max-width: 100%;
    img{
      max-width: 100%;
    }
  }
  .filledAvatar{
    background-color: #E4EFEA;
  }
`;
export const NetworkStyle = styled.div`
    /* .customOwl{
      padding:22px;
      min-height: 200px;
      max-height: 200px;
      background-color: #d7eee4;
      .item{
        min-height: 157px;
        max-height: 157px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__years{
          h3{
            font-size:52px;
            line-height:45px;
            font-weight: 500;
            color: #000000;
            font-family: 'Urbanist', sans-serif;
          }
          span{
            padding-top: 2px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        &__design{
          justify-content: flex-end;
          display: flex;
          p{
            width: 100%;
            max-width: 85px;
            text-align: right;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }
    } */
    .bannerCarousel{
      .ant-carousel{
        padding:22px;
        min-height: 200px;
        max-height: 200px;
        background-color: #d7eee4;
        ${md(`
          min-height: 150px;
          max-height: 150px;
        `)}
        .carouselItem{
          height: 160px;
          position: relative;
          ${md(`
            height: auto;
          `)}
            .count{
              h2{
                font-size: 52px;
                line-height: 45px;
                font-weight: 500;
                color: rgb(0, 0, 0);
                font-family: Urbanist, sans-serif;
              }
              span{
                padding-top: 2px;
                font-size: 14px;
                font-weight: 500;
              }
            }
          .info{
            position: absolute;
            bottom: 0;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            ${md(`
              position:relative;
            `)}
            p{
                width: 100%;
                max-width: 85px;
                text-align: right;
                font-size: 14px;
                font-weight: 500;
                line-height: 120%;
            }
          }
        }
      }
    }
    .groupAvatar{
      padding: 22px;
      min-height: 200px;
      max-height: 200px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ${md(`
        min-height:130px;
        max-height:130px;
      `)}
    }
`;

export const AboutStyle = styled.div`
    display: flex;
    align-items:flex-end; 
    justify-content:space-between;
    width:100%;
    p{
      width: 100%;
      max-width: 78%;
      font-size: 16px;
      font-weight: 400;
      line-height:32px;
      letter-spacing:1px;
      ${lg(`
        width: 70%;
        max-width: 70%;
        font-size: 14px;
        line-height:20px;
      `)}
    }
    a{
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      .anticon{
        margin-left:12px;
      }
    }
    ${md(`
        display: none;
  `)}
`;

export const DescriptionStyle = styled.p`
    display: flex;
`;
export const TitleStyle = styled.h4`
   font-size: 14px;
   font-weight: 500;
   color: #000;

`;
export const LinkStyle = styled(Link)`
    display: flex;
    .anticon{
      svg{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-in;
      }
    }
    &:hover .anticon{
      svg{
        transform: translate3d(4px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-out;
      }
    }
`;


export const CoverStyle = styled.div`
  display: flex;
  flex-direction: column;
  .reverse{
    ${md(`
        flex-direction: column-reverse;
        width:auto;
  `)}
  }
  .ant-divider{
    ${md(`
        display:none;
  `)}
  }
`;


