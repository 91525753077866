export const images = { 
    //Home
    bannerHero: require('./../Assets/Images/hero.webp'),
    userOne: require('./../Assets/Images/userOne.webp'),
    userTwo: require('./../Assets/Images/userTwo.webp'),

   //Our Mission
   ourMision: require('./../Assets/Images/whoWe.webp'),
   userTwo: require('./../Assets/Images/userTwo.webp'),

    //Project
    anahrjobs: require('./../Assets/Images/Projects/anahrJobs.webp'),
    anahrJobsThumb: require('./../Assets/Images/Projects/anahrJobsThumb.webp'),
    aks: require('./../Assets/Images/Projects/ask.webp'),
    askThumb: require('./../Assets/Images/Projects/askThumb.webp'),
    aksAdmin: require('./../Assets/Images/Projects/askAdmin.webp'),
    askAdminThumb: require('./../Assets/Images/Projects/askAdminThumb.webp'),
    bidonwork: require('./../Assets/Images/Projects/bidonWork.webp'),
    bidonworkThumb: require('./../Assets/Images/Projects/bidonWorkThumb.webp'),
    bloomy: require('./../Assets/Images/Projects/bloomy.webp'),
    bloomyThumb: require('./../Assets/Images/Projects/bloomyThumb.webp'),
    corazon: require('./../Assets/Images/Projects/corazon.webp'),
    corazonThumb: require('./../Assets/Images/Projects/corazonThumb.webp'),
    crimewave: require('./../Assets/Images/Projects/crimewave.webp'),
    crimewaveThumb: require('./../Assets/Images/Projects/crimewaveThumb.webp'),
    dabump: require('./../Assets/Images/Projects/dabump.webp'),
    dabumpThumb: require('./../Assets/Images/Projects/dabumpThumb.webp'),
    dabumpApp: require('./../Assets/Images/Projects/dabumpApp.webp'),
    dabumpAppThumb: require('./../Assets/Images/Projects/dabumpAppThumb.webp'),
    docpal: require('./../Assets/Images/Projects/docpal.webp'),
    docpalThumb: require('./../Assets/Images/Projects/docpalThumb.webp'),
    faci: require('./../Assets/Images/Projects/faci.webp'),
    faciThumb: require('./../Assets/Images/Projects/faciThumb.webp'),
    firstholding: require('./../Assets/Images/Projects/firstholding.webp'),
    firstholdingThumb: require('./../Assets/Images/Projects/firstholdingThumb.webp'),
    gearexpress: require('./../Assets/Images/Projects/gearexpress.webp'),
    gearexpressThumb: require('./../Assets/Images/Projects/gearexpressThumb.webp'),
    gear: require('./../Assets/Images/Projects/gear.webp'),
    gearThumb: require('./../Assets/Images/Projects/gearThumb.webp'),
    gmvss: require('./../Assets/Images/Projects/gmvss.webp'),
    gmvssThumb: require('./../Assets/Images/Projects/gmvssThumb.webp'),
    jrLogistics: require('./../Assets/Images/Projects/jrLogistics.webp'),
    jrLogisticsThumb: require('./../Assets/Images/Projects/jrLogisticsThumb.webp'),
    juicy: require('./../Assets/Images/Projects/juicy.webp'),
    juicyThumb: require('./../Assets/Images/Projects/juicyThumb.webp'),
    kinindia: require('./../Assets/Images/Projects/kinindia.webp'),
    kinindiaThumb: require('./../Assets/Images/Projects/kinindiaThumb.webp'),
    koli: require('./../Assets/Images/Projects/koli.webp'),
    koliThumb: require('./../Assets/Images/Projects/koliThumb.webp'),
    koliApp: require('./../Assets/Images/Projects/koliApp.webp'),
    koliAppThumb: require('./../Assets/Images/Projects/koliAppThumb.webp'),
    loanbuddy: require('./../Assets/Images/Projects/loanbuddy.webp'),
    loanbuddyThumb: require('./../Assets/Images/Projects/loanbuddyThumb.webp'),
    loanbuddyAdmin: require('./../Assets/Images/Projects/loanbuddyAdmin.webp'),
    loanbuddyAdminThumb: require('./../Assets/Images/Projects/loanbuddyAdminThumb.webp'),
    logisticsllp: require('./../Assets/Images/Projects/logisticsllp.webp'),
    logisticsllpThumb: require('./../Assets/Images/Projects/logisticsllpThumb.webp'),
    maxarbitrage: require('./../Assets/Images/Projects/maxarbitrage.webp'),
    maxarbitrageThumb: require('./../Assets/Images/Projects/maxarbitrageThumb.webp'),
    otium: require('./../Assets/Images/Projects/otium.webp'),
    otiumThumb: require('./../Assets/Images/Projects/otiumThumb.webp'),
    poshan: require('./../Assets/Images/Projects/poshan.webp'),
    poshanThumb: require('./../Assets/Images/Projects/poshanThumb.webp'),
    propease: require('./../Assets/Images/Projects/propease.webp'),
    propeaseThumb: require('./../Assets/Images/Projects/propeaseThumb.webp'),
    recruitmind: require('./../Assets/Images/Projects/recruitmind.webp'),
    recruitmindThumb: require('./../Assets/Images/Projects/recruitmindThumb.webp'),
    saveonclick: require('./../Assets/Images/Projects/saveonclick.webp'),
    saveonclickThumb: require('./../Assets/Images/Projects/saveonclickThumb.webp'),
    spherium: require('./../Assets/Images/Projects/spherium.webp'),
    spheriumThumb: require('./../Assets/Images/Projects/spheriumThumb.webp'),
    tgb: require('./../Assets/Images/Projects/tgb.webp'),
    tgbThumb: require('./../Assets/Images/Projects/tgbThumb.webp'),
    tgbAdmin: require('./../Assets/Images/Projects/tgbAdmin.webp'),
    tgbAdminThumb: require('./../Assets/Images/Projects/tgbAdminThumb.webp'),
    ultima: require('./../Assets/Images/Projects/ultima.webp'),
    ultimaThumb: require('./../Assets/Images/Projects/ultimaThumb.webp'),
    uniqstop: require('./../Assets/Images/Projects/uniqstop.webp'),
    uniqstopThumb: require('./../Assets/Images/Projects/uniqstopThumb.webp'),
    vivsuneng: require('./../Assets/Images/Projects/vivsuneng.webp'),
    vivsunengThumb: require('./../Assets/Images/Projects/vivsunengThumb.webp'),
    washton: require('./../Assets/Images/Projects/washton.webp'),
    washtonThumb: require('./../Assets/Images/Projects/washtonThumb.webp'),
    wedove: require('./../Assets/Images/Projects/wedove.webp'),
    wedoveThumb: require('./../Assets/Images/Projects/wedoveThumb.webp'),
  
    //service
    iconDiamond: require('./../Assets/Images/diamond.webp'),
    appDevelopmentservice: require('./../Assets/Images/services/AppDevelopment.webp'),
    webDevelopment: require('./../Assets/Images/services/WebDevelopment.webp'),
    digitalMarketting: require('./../Assets/Images/services/DigitalMarketting.webp'),
    uXDesign: require('./../Assets/Images/services/UXDesign.webp'),
    analytics: require('./../Assets/Images/services/Analytics.webp'),
    consultationStrategy: require('./../Assets/Images/services/ConsultationStrategy.webp'),

    //Articles
    blogThumbHero: require('./../Assets/Images/blogThumbHero.webp'),
    eCommerce: require('./../Assets/Images/blogs/e-CommerceThumbnail.webp'),
    appDevelopment: require('./../Assets/Images/blogs/appDevelopmentThumbnail.webp'),

    //Client Says
    larry: require('./../Assets/Images/client/larry.webp'),
    renato: require('./../Assets/Images/client/renato.webp'),
    prateek: require('./../Assets/Images/client/prateek.webp'),
    luv: require('./../Assets/Images/client/luv.webp'),

    //Extraordinary
    readyToCreate: require('./../Assets/Images/ReadyToCreateHero.webp'),
    readyToCreateIcon: require('./../Assets/Images/readyToCreate.webp'),

    //About
    aboutHero: require('./../Assets/Images/aboutBanner.webp'),
    aboutInfo: require('./../Assets/Images/aboutuInfo.webp'),
    howWeWork: require('./../Assets/Images//howWeWork.webp'),

    //Footer
    footerLogo: require('./../Assets/Images/footerLogo.webp'),

    //Our Team
    amitPrajapat: require('./../Assets/Images/amitPrajapat.webp'),
    avinashKumar: require('./../Assets/Images/avinashKumar.webp'),
    jayeshMalhotra: require('./../Assets/Images/jayeshMalhotra.webp'),
    soniaArora: require('./../Assets/Images/soniaArora.webp'),
    faqs: require('./../Assets/Images/faq.webp'),

    //Blog
    eCommerceThumb: require('./../Assets/Images/blogs/e-CommerceThumbnail.webp'),
    eCommerceBanner: require('./../Assets/Images/blogs/e-CommerceBanner.webp'),
    appDevelopThumb: require('./../Assets/Images/blogs/appDevelopmentThumbnail.webp'),
    appDevelopBanner: require('./../Assets/Images/blogs/appDevelopmentBanner.webp'),
    digitalMarkettingThumb: require('./../Assets/Images/blogs/digitalMarkettingThumbnail.webp'),
    digitalMarkettingBanner: require('./../Assets/Images/blogs/digitalMarkettingBanner.webp'),
    uiuxDesignThumb: require('./../Assets/Images/blogs/uxDesignsThumbnail.webp'),
    uiuxDesignBanner: require('./../Assets/Images/blogs/uxDesignsBanner.webp'),

    //Careers
    careersatgrratz: require('./../Assets/Images/careersAtGrratz.webp'),
    benefitsPerks: require('./../Assets/Images/benefitsPerks.webp'),
    whychooseus: require('./../Assets/Images/whyChooseUs.webp'),

     //Contact Us
     contactus: require('./../Assets/Images/contactus.webp'),
};

