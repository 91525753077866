import { HeaderRight, HeaderStyle, HeaderInnerStyle, MenuStyle } from "./Header.styles";
import { Link, NavLink } from "react-router-dom";
import {Container} from "./../Container/Container"
import { Logo, OpenMenu } from "../Icon";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { useState } from "react";
import useScrolling from '../../Hooks/useScrolling';

const items = [
  {
    label: <NavLink to="/about" aria-label="Redirect to about page">Culture</NavLink>,
    key: "culture",
  },
  {
    label: <NavLink to="/projects" aria-label="Redirect to project page">Projects</NavLink>,
    key: "projects",
  },
  {
    label: <NavLink to="/services" aria-label="Redirect to services page">Services</NavLink>,
    key: "services",
  },
  {
    label: <NavLink to="/blogs" aria-label="Redirect to blogs page">Resources</NavLink>,
    key: "blogs",
  }
];

const Header = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(min-width: 769px) and (max-width: 1024px)');
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const [isListVisible, setListVisible] = useState(false);
  const layoutClass = isMobile ? "responsiveLayout wrapperEven" : "webLayout wrapperEven";
  const useScrolled = useScrolling();
  const handleToggle = () => {
    setListVisible(!isListVisible);
  };

  return (
  <HeaderStyle className={`${layoutClass} ${useScrolled ? 'scrolled' : ''}`}>
      <Container>
        <HeaderInnerStyle>
        <Link className="logoBlock" to="/">
          <Logo/>
          <span className="tagline">Digital excellence, Unified solutions</span>
        </Link>
          
          {isListVisible && (
            <MenuStyle  mode="vertical" items={items} />
          )}

          <MenuStyle  mode="horizontal" items={items} />

          <HeaderRight>
            <Link to="/contact" className="linkBtn">Let’s Talk</Link>
            {isMobile && (
              <span onClick={handleToggle} className="hamburgerMenu"><OpenMenu/></span>
            )}
          </HeaderRight>
        </HeaderInnerStyle>  
      </Container>
    </HeaderStyle>
  );
};

export default Header;
