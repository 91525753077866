import { Container } from "../Container/Container";
import { WrapperStyle } from "./About.styles";
import React from 'react';
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Col, Row, Typography } from "antd";
const { Title, Paragraph } = Typography;
import { Constants } from "../../db/Constants";

function Aboutus() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperEven" : "webLayout wrapperEven";
  return (
    <WrapperStyle>
     <Container>
        <Title level={2}>{Constants?.aboutUs?.title}</Title>
        <div className="aboutus">
          <Row gutter={[30, 30]}>
            <Col xs={24} md={15} lg={15}>
              <div className="aboutus__info">
                <img src={Constants?.aboutUs?.imageHero} alt={'BannerImg'} className="imgResponsive"/>
                {/* <Paragraph>{Constants?.aboutUs?.dicription}</Paragraph> */}
                <Paragraph>Welcome to Grratz, where innovation meets excellence in the realms of mobile and web development, coupled with cutting-edge digital marketing strategies. As a dynamic force in the tech industry, we pride ourselves on crafting solutions that transcend expectations and drive success for our clients.</Paragraph>
              </div>
            </Col>
            <Col xs={24} md={9} lg={9}>
              <div className="aboutus__img">
                <img src={Constants?.aboutUs?.imageRight} alt={'InfoImg'} className="imgResponsive"/>
              </div>
            </Col>
          </Row>
        </div>
     </Container>
    </WrapperStyle>
  );
}
export default Aboutus;
