import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const AboutListSec = styled.section`
    width: 100%;
    padding:80px 0;
    h1.ant-typography{
        font-family: Urbanist, sans-serif;
        font-size: 48px;
        font-weight: 400;
        color: #000;
        margin-bottom:50px;
    }
    .contentCover{
        width:100%;
        margin-bottom:30px;
        h3.ant-typography{
            font-family: Urbanist, sans-serif;
            font-size: 28px;
            font-weight: 500;
            color: #000;
            margin-bottom:15px;
        }
        .ant-typography{
            font-family: Inter, sans-serif;
            font-size: 16px;
            line-height:28px;
            font-weight: 400;
            span{
              font-weight:700;
              margin-right:5px;
            }
        }
        .listItem{
            list-style-image: url("../img/check-gray.svg");
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            padding:0;
            margin:20px 0 0 30px;
            li{
              width:100%;
              margin-bottom:15px;
              span{
                position:relative;
                top:-6px;
              }
            }
          }
    }
`