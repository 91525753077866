import { WrapperStyle, LinkStyle } from "./OurServicesList.styles";
import { Col, Row, Flex  } from "antd";
import { LinkArrow } from "../Icon";
import useMediaQuery from '../../Hooks/useMediaQuery';
const OurServicesList = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle>
      <Row gutter={[16, 16]}>
            <Flex wrap="wrap" justify={"space-between"} align={"center"} className="ourServices">
              {props?.serviceList?.map((list, index) => (
                  <Col xs={24} md={12} lg={12} key={index}>
                    <LinkStyle to={list.link} key={index} aria-description='Redirect to service page'>
                      <div className="ourServices__cards" id={`active-${list.id}`}>
                        <div className="iconCount">
                          <p>{list.id}</p>
                          <img src={list.image} alt={list.title}/>
                        </div>
                        <div className="infoMore">
                        <div className="topServices">
                          {list.icon ? <img src={list.icon} alt='DimondImg'/> : ' '}{list.topService}
                        </div>
                          <div>
                            <h2>{list.title}</h2>
                            <p>{list.dicription}</p>
                          </div>
                          <LinkStyle to={list.link} aria-description={`Redirect to ${list.title} page`}>{`${list.serviceLink} Details`}<LinkArrow/></LinkStyle>
                        </div>
                      </div>
                    </LinkStyle>
                  </Col>
              ))
              }
            </Flex>
          </Row>
    </WrapperStyle>
  );
};

export default OurServicesList;