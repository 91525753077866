
import { Flex, Typography } from "antd";
import { AboutListSec } from "./DigitalMarketing.styles";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import { ButtonStyle } from "../../Component/Button/Button.style";

const { Title } = Typography;

function DigitalMarketing() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Elevate Your Online Presence with Grratz Digital Marketing Services</Title>
          <div className="serviceContenet">
          <p className="content">At Grratz, we specialize in driving online growth and maximizing digital success for businesses of all sizes. With our comprehensive suite of digital marketing services and a team of experienced professionals, we're dedicated to helping you reach your target audience, increase brand visibility, and generate leads and conversions. Whether you're a startup looking to establish your presence or an established enterprise aiming to expand your digital footprint, we're here to partner with you and achieve your marketing objectives</p>
          <div className="list">
          <Title level={3}>Our Services:</Title>
            <ul>
            <Title level={5}>Search Engine Optimization (SEO):</Title>
              <li>Our SEO experts will optimize your website to improve its visibility in search engine results pages (SERPs), driving organic traffic and increasing your site's authority and relevance.</li>
              <Title level={5}>Pay-Per-Click (PPC) Advertising:</Title>
              <li>We design and manage targeted PPC campaigns to drive immediate traffic and conversions. From keyword research to ad copywriting and performance tracking, we'll maximize your ROI and ensure your ad spend delivers results.</li>
              <Title level={5}>Social Media Marketing (SMM):</Title>
              <li>Engage and grow your audience on social media platforms with our strategic SMM services. We'll develop tailored content, manage your social accounts, and run targeted ad campaigns to boost engagement, drive traffic, and foster brand loyalty.</li>
              <Title level={5}>Content Marketing:</Title>
              <li>Content is king, and we're here to help you rule the digital landscape. From blog posts and articles to videos and infographics, our content marketing strategies will attract, engage, and convert your audience at every stage of the buyer's journey.</li>
              <Title level={5}>Email Marketing:</Title>
              <li>Nurture leads and build relationships with our effective email marketing campaigns. We'll create compelling content, design eye-catching templates, and segment your audience to deliver personalized messages that drive engagement and conversions.</li>
            </ul>
          </div>

          <div className="list">
          <Title level={3}>Why Choose Grratz?</Title>
            <ul>
              <Title level={5}>Proven Results:</Title>
              <li>With a track record of success and satisfied clients, we have the expertise and experience to deliver tangible results and achieve your marketing objectives.</li>
              <Title level={5}>Customized Strategies:</Title>
              <li>We understand that every business is unique. That's why we take a tailored approach to each client, developing customized strategies that align with your goals, budget, and target audience.</li>
              <Title level={5}>Transparent Reporting:</Title>
              <li>Stay informed and in control with our transparent reporting and analytics. We'll provide regular updates and insights into your campaign performance, so you always know how your marketing efforts are driving results.</li>
              <Title level={5}>Dedicated Support:</Title>
              <li>Our team is here to support you every step of the way. Whether you have questions, need guidance, or want to explore new opportunities, we're just a phone call or email away.</li>
            </ul>
          </div>

          <div className="titleText">
            <Title level={2}>Get Started Today!</Title>
            <p>Ready to take your digital marketing efforts to the next level? Contact us today to schedule a consultation and discover how Grratz can help you achieve your online marketing goals. Let's work together to elevate your brand and drive success in the digital world</p>
          </div>
           <Link to="mailto:hello@grratz.com" className="mailLink">hello@grratz.com</Link>
            <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" href="/contact" shape="round" size={'large'}>Contact Us</ButtonStyle>
            </Flex>
          </div>
          <Faq/>
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default DigitalMarketing;
