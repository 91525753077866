import { WrapperStyle, SectionStyle, CoverStyle } from "./OurTeam.styles";
import { Container } from "../../Component/Container/Container";
import { Col, Row } from "antd";
import SelectedWorked from "../SelectedWorked/SelectedWorked";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Constants } from "../../db/Constants";
console.log(Constants?.ourTeam?.iconList);
const OurTeam = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24}>
            <SectionStyle>
              <p>{Constants?.ourTeam?.decription}</p>
            </SectionStyle>
          </Col>
        </Row>
        <SelectedWorked/>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default OurTeam;
