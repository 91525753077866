import { WrapperStyle } from "./HowWeWork.styles";
import { Container } from "../Container/Container";
import { Col, Row, Tag, Flex, Typography  } from "antd";
import { Link } from "react-router-dom";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Constants } from "../../db/Constants";
const { Paragraph } = Typography;

const HowWeWork = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <Tag>{Constants?.howWeWork?.subTitle}</Tag>
        <h2>{Constants?.howWeWork?.title}</h2>
        <Row gutter={[16, 16]}>
          <Flex wrap="wrap" justify={"space-between"} align={"center"} className="howWork">
            <Col xs={24} md={12} lg={12}>
              <div className="howWork__img">
                <img src={Constants?.howWeWork?.imageHero} alt='workImg' className="imgResponsive"/>
              </div> 
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="howWork__info">
                <Paragraph>{Constants?.aboutUs?.dicription}</Paragraph>
                <ul className="listItem">
                  {Constants?.howWeWork?.workList?.map((list, index) => (
                    <li key={index}><span>{list.dicription}</span></li>
                  ))}
                </ul>
                <Link to={Constants?.howWeWork?.link} className="linkBtn" aria-label="Redirect to work page">{Constants?.howWeWork?.linkText}</Link>
              </div>
            </Col>
          </Flex>
        </Row>
      </Container>
    </WrapperStyle>
  );
};

export default HowWeWork;
