import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import Home from "./Pages/Home/Home";
import { ThemeProvider } from "styled-components";
import { useColorConfig } from "./ThemeConfig/UseColorConfig"; 
import About from "./Pages/About/About";
import GlobalStyle from './Global.style';
import Layout from "./Layout/Layout";
import { antDComponents, antDTheme, customTheme } from "./ThemeConfig/Variables";
import Works from "./Pages/Works/Works";
import Services from "./Pages/Services/Services";
import Blogs from "./Pages/Blogs/Blogs";
import Contact from "./Pages/Contact/Contact";
import Careers from "./Pages/Careers/Careers";
import Policy from "./Pages/Policy/Policy";
import Terms from "./Pages/Terms/Terms";
import WorksDescription from "./Pages/WorksDescription/WorksDescription";
import ServiceDescription from "./Pages/ServiceDescription/ServiceDescription";
import UnveilingFutureBlog from "./Pages/UnveilingFutureBlog/UnveilingFutureBlog";
import MobileAppDevelopmentBlog from "./Pages/MobileAppDevelopmentBlog/MobileAppDevelopmentBlog";
import PowerofStoryBlog from "./Pages/PowerofStoryBlog/PowerofStoryBlog";
import UIUXDesignsBlog from "./Pages/UIUXDesignsBlog/UIUXDesignsBlog";
import AnalyticsReporting from "./Pages/AnalyticsReporting/AnalyticsReporting";
import AppDevelopment from "./Pages/AppDevelopment/AppDevelopment";
import WebDevelopment from "./Pages/WebDevelopment/WebDevelopment";
import DigitalMarketing from "./Pages/DigitalMarketing/DigitalMarketing";
import UIDesigning from "./Pages/UIDesigning/UIDesigning";
import ConsultationStrategy from "./Pages/ConsultationStrategy/ConsultationStrategy";

function App() {
  const [handleChange, themeColor, themevalue] = useColorConfig();
  return (
    <ConfigProvider
      theme={{
        token: antDTheme(themeColor),
        components: antDComponents(themeColor),
      }}
    >
      <ThemeProvider theme={customTheme(themeColor)}>
        <GlobalStyle />
          <Routes>
            <Route
              element={
                <Layout themeChange={handleChange} themevalue={themevalue} />
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Works />} />
              <Route path="/works-description" element={<WorksDescription/>} />
              <Route path="/services" element={<Services />} />
              <Route path="/service-description" element={<ServiceDescription/>} />
              <Route path="/blogs" element={<Blogs/>} />
              <Route path="/futureBlog" element={<UnveilingFutureBlog/>} />
              <Route path="/mobile" element={<MobileAppDevelopmentBlog/>} />
              <Route path="/story-telling" element={<PowerofStoryBlog/>} />
              <Route path="/ui-designs" element={<UIUXDesignsBlog/>} />
              <Route path="/app" element={<AppDevelopment/>} />
              <Route path="/web" element={<WebDevelopment/>} />
              <Route path="/marketing" element={<DigitalMarketing/>} />
              <Route path="/designing" element={<UIDesigning/>} />
              <Route path="/analytics" element={<AnalyticsReporting/>} />
              <Route path="/consultation" element={<ConsultationStrategy/>} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/terms" element={<Terms/>} />
            </Route>
            <Route element={<Layout themeChange={handleChange} />}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Works />} />
              <Route path="/works-description" element={<WorksDescription/>} />
              <Route path="/services" element={<Services />} />
              <Route path="/service-description" element={<ServiceDescription/>} />
              <Route path="/blogs" element={<Blogs/>} />
              <Route path="/futureBlog" element={<UnveilingFutureBlog/>} />
              <Route path="/mobile" element={<MobileAppDevelopmentBlog/>} />
              <Route path="/story-telling" element={<PowerofStoryBlog/>} />
              <Route path="/ui-designs" element={<UIUXDesignsBlog/>} />
              <Route path="/app" element={<AppDevelopment/>} />
              <Route path="/web" element={<WebDevelopment/>} />
              <Route path="/marketing" element={<DigitalMarketing/>} />
              <Route path="/designing" element={<UIDesigning/>} />
              <Route path="/analytics" element={<AnalyticsReporting/>} />
              <Route path="/consultation" element={<ConsultationStrategy/>} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/terms" element={<Terms/>} />
            </Route>
          </Routes>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default App;
