import styled from "styled-components";

export const AboutListSec = styled.section`
    width: 100%;
    h2.ant-typography{
        font-family: Urbanist, sans-serif;
        font-size: 48px;
        font-weight: 400;
        color:#000;
        margin:60px 0 50px 0;
    }
`;