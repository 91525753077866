  import React from "react";
  import { withTheme } from "styled-components";

  function Logo(props) {
    const { theme: logoColor } = props;

    return (
      <span className="logo">
        <svg width="304" height="94" viewBox="0 0 304 94" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_245_136" maskUnits="userSpaceOnUse" x="0" y="0" width="304" height="94">
  <path d="M303.99 0H0V93.23H303.99V0Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_245_136)">
  <path d="M49.7609 43.4101C49.1809 57.5201 38.3809 64.5101 27.5109 64.5101C14.6209 64.5101 1.88086 54.0001 1.88086 38.8001C1.88086 23.6001 14.7709 13.1001 27.3009 13.1001C32.2009 13.1001 36.0809 14.3201 38.9709 15.9101C41.8509 17.7801 43.7909 19.5801 43.7909 22.3201C43.7909 25.4201 41.3409 27.1401 38.7509 27.1401C36.7309 27.1401 35.3709 26.1301 34.5709 25.6301C33.7809 25.2001 31.4009 23.3301 27.4409 23.3301C19.7409 23.3301 13.2609 30.1701 13.2609 38.8101C13.2609 47.4501 19.6709 54.2901 27.5209 54.2901C32.2709 54.2901 38.3209 51.3401 39.1909 45.5801H32.2109C29.7609 45.5801 27.5309 43.7101 27.5309 41.1201C27.5309 38.5301 29.7609 36.7301 32.2109 36.7301H43.8809C47.9809 36.7301 49.9309 39.5401 49.7809 43.4301L49.7609 43.4101Z" fill="#1CB765"/>
  <path d="M101.171 59.1801C101.171 61.9901 99.0814 64.2201 96.2714 64.2201C94.5414 64.2201 93.1714 63.4301 92.3114 62.2001L79.9314 46.5001H73.7414V59.1001C73.7414 62.0501 71.3614 64.5701 68.4114 64.5701C65.3114 64.5701 62.9414 62.0501 62.9414 59.1001V19.5801C62.9414 16.1201 65.4614 13.6001 68.9214 13.6001H82.1014C93.3314 13.6001 100.601 21.0901 100.601 30.1601C100.601 36.6401 96.9314 42.1901 90.6614 44.8501L99.8814 55.8701C100.601 56.7301 101.171 57.8901 101.171 59.1801ZM73.7414 37.2901H81.5214C86.2714 37.2901 90.0914 35.7801 90.0914 30.1601C90.0914 24.5401 86.2714 22.8201 81.5214 22.8201H73.7414V37.2901Z" fill="#1CB765"/>
  <path d="M152.361 59.1801C152.361 61.9901 150.271 64.2201 147.461 64.2201C145.731 64.2201 144.361 63.4301 143.501 62.2001L131.121 46.5001H124.931V59.1001C124.931 62.0501 122.551 64.5701 119.601 64.5701C116.651 64.5701 114.131 62.0501 114.131 59.1001V19.5801C114.131 16.1201 116.651 13.6001 120.111 13.6001H133.291C144.521 13.6001 151.791 21.0901 151.791 30.1601C151.791 36.6401 148.121 42.1901 141.851 44.8501L151.071 55.8701C151.791 56.7301 152.371 57.8901 152.371 59.1801H152.361ZM124.931 37.2901H132.711C137.461 37.2901 141.281 35.7801 141.281 30.1601C141.281 24.5401 137.461 22.8201 132.711 22.8201H124.931V37.2901Z" fill="#1CB765"/>
  <path d="M256.11 18.4299C256.11 21.0999 253.95 23.1799 251.36 23.1799H241.85V58.8899C241.85 61.9899 239.33 64.5799 236.45 64.5799C233.57 64.5799 230.98 61.9899 230.98 58.8899V23.1799H221.55C218.96 23.1799 216.73 21.0899 216.73 18.4299C216.73 15.7699 218.96 13.6099 221.55 13.6099H251.36C253.95 13.6099 256.11 15.6999 256.11 18.4299Z" fill="#1CB765"/>
  <path d="M302.121 25.1201L278.211 54.4301H299.241C301.831 54.4301 303.991 56.5201 303.991 59.2501C303.991 61.9801 301.831 64.0001 299.241 64.0001H274.831C268.421 64.0001 264.611 57.5901 268.571 52.4801L292.401 23.1701H273.251C270.661 23.1701 268.431 21.0801 268.431 18.4201C268.431 15.7601 270.661 13.6001 273.251 13.6001H295.791C302.271 13.6001 306.091 20.0101 302.131 25.1201H302.121Z" fill="#1CB765"/>
  <path d="M47.88 42.4101C47.3 56.5201 36.5 63.5101 25.63 63.5101C12.75 63.5101 0 53.0001 0 37.8001C0 22.6001 12.89 12.1001 25.42 12.1001C30.32 12.1001 34.2 13.3201 37.09 14.9101C39.97 16.7801 41.91 18.5801 41.91 21.3201C41.91 24.4201 39.46 26.1401 36.87 26.1401C34.85 26.1401 33.49 25.1301 32.69 24.6301C31.9 24.2001 29.52 22.3301 25.56 22.3301C17.86 22.3301 11.38 29.1701 11.38 37.8101C11.38 46.4501 17.79 53.2901 25.64 53.2901C30.39 53.2901 36.44 50.3401 37.31 44.5801H30.33C27.88 44.5801 25.65 42.7101 25.65 40.1201C25.65 37.5301 27.88 35.7301 30.33 35.7301H42C46.1 35.7301 48.05 38.5401 47.9 42.4301L47.88 42.4101Z" fill="#14D675"/>
  <path d="M99.2905 58.1801C99.2905 60.9901 97.2005 63.2201 94.3905 63.2201C92.6605 63.2201 91.2905 62.4301 90.4305 61.2001L78.0505 45.5001H71.8605V58.1001C71.8605 61.0501 69.4805 63.5701 66.5305 63.5701C63.5805 63.5701 61.0605 61.0501 61.0605 58.1001V18.5801C61.0605 15.1201 63.5805 12.6001 67.0405 12.6001H80.2205C91.4505 12.6001 98.7205 20.0901 98.7205 29.1601C98.7205 35.6401 95.0505 41.1901 88.7805 43.8501L98.0005 54.8701C98.7205 55.7301 99.3005 56.8901 99.3005 58.1801H99.2905ZM71.8605 36.2901H79.6405C84.3905 36.2901 88.2105 34.7801 88.2105 29.1601C88.2105 23.5401 84.3905 21.8201 79.6405 21.8201H71.8605V36.2901Z" fill="#14D675"/>
  <path d="M150.48 58.1801C150.48 60.9901 148.39 63.2201 145.58 63.2201C143.85 63.2201 142.48 62.4301 141.62 61.2001L129.24 45.5001H123.05V58.1001C123.05 61.0501 120.67 63.5701 117.72 63.5701C114.62 63.5701 112.25 61.0501 112.25 58.1001V18.5801C112.25 15.1201 114.77 12.6001 118.23 12.6001H131.41C142.64 12.6001 149.92 20.0901 149.92 29.1601C149.92 35.6401 146.25 41.1901 139.98 43.8501L149.2 54.8701C149.92 55.7301 150.5 56.8901 150.5 58.1801H150.48ZM123.05 36.2901H130.83C135.58 36.2901 139.4 34.7801 139.4 29.1601C139.4 23.5401 135.58 21.8201 130.83 21.8201H123.05V36.2901Z" fill="#14D675"/>
  <path d="M254.229 17.4299C254.229 20.0999 252.069 22.1799 249.479 22.1799H239.979V57.8899C239.979 60.9899 237.459 63.5799 234.579 63.5799C231.699 63.5799 229.109 60.9899 229.109 57.8899V22.1799H219.679C217.089 22.1799 214.859 20.0899 214.859 17.4299C214.859 14.7699 217.089 12.6099 219.679 12.6099H249.489C252.079 12.6099 254.239 14.6999 254.239 17.4299H254.229Z" fill="#14D675"/>
  <path d="M300.241 24.1201L276.331 53.4301H297.361C299.951 53.4301 302.111 55.5201 302.111 58.2501C302.111 60.9801 299.951 63.0001 297.361 63.0001H272.951C266.541 63.0001 262.731 56.5901 266.691 51.4801L290.521 22.1701H271.371C268.781 22.1701 266.551 20.0801 266.551 17.4201C266.551 14.7601 268.781 12.6001 271.371 12.6001H293.911C300.391 12.6001 304.211 19.0101 300.251 24.1201H300.241Z" fill="#14D675"/>
  <path d="M183.36 25.1701C187.601 25.1701 191.04 21.7316 191.04 17.4901C191.04 13.2485 187.601 9.81006 183.36 9.81006C179.118 9.81006 175.68 13.2485 175.68 17.4901C175.68 21.7316 179.118 25.1701 183.36 25.1701Z" fill="#1CB765"/>
  <path d="M183.36 25.1701C187.601 25.1701 191.04 21.7316 191.04 17.4901C191.04 13.2485 187.601 9.81006 183.36 9.81006C179.118 9.81006 175.68 13.2485 175.68 17.4901C175.68 21.7316 179.118 25.1701 183.36 25.1701Z" fill="#3A3A3A"/>
  <path d="M182.939 63.5301L179.039 59.0801C178.929 58.9601 178.889 58.8001 178.909 58.6401L182.679 32.8701C182.699 32.7301 182.669 32.5801 182.579 32.4701L180.699 29.8701C180.619 29.7601 180.499 29.6901 180.369 29.6601C163.029 25.7801 166.289 10.4001 167.099 8.80009C167.909 7.19009 169.289 5.17009 168.079 2.52009C166.869 -0.149914 164.159 8.6444e-05 164.159 8.6444e-05C159.679 8.6444e-05 157.119 7.68009 157.119 13.4401C157.119 19.2001 159.109 23.4601 160.959 26.2401C162.859 29.1001 166.079 32.6401 167.359 38.4001C168.639 44.1601 166.719 51.8401 166.079 56.9601C165.439 62.0801 166.309 64.3801 167.999 65.9201C170.789 68.4701 180.269 72.8501 182.759 73.9701C183.149 74.1401 183.579 74.1401 183.959 73.9701C186.439 72.8501 195.919 68.4701 198.719 65.9201C200.409 64.3801 201.279 62.0801 200.639 56.9601C199.999 51.8401 198.079 44.1601 199.359 38.4001C200.639 32.6401 203.859 29.1001 205.759 26.2401C207.609 23.4601 209.599 19.2001 209.599 13.4401C209.599 7.68009 207.039 8.6444e-05 202.559 8.6444e-05C202.559 8.6444e-05 199.849 -0.149914 198.639 2.50009C197.429 5.15009 198.809 7.17009 199.619 8.78009C200.419 10.3901 203.679 25.7701 186.349 29.6401C186.219 29.6701 186.099 29.7401 186.019 29.8501L184.139 32.4501C184.059 32.5701 184.019 32.7101 184.039 32.8501L187.809 58.6201C187.829 58.7801 187.789 58.9401 187.679 59.0601L183.779 63.5101C183.559 63.7601 183.169 63.7601 182.949 63.5101L182.939 63.5301Z" fill="#1CB765"/>
  <path d="M185.45 10.1099C188.28 11.3299 190.26 14.1499 190.26 17.4299C190.26 21.0599 187.84 24.1099 184.52 25.0799C188.21 24.5199 191.03 21.3399 191.03 17.4999C191.03 13.9799 188.67 11.0299 185.44 10.1199L185.45 10.1099Z" fill="#212121"/>
  <path d="M181.939 63.5301L178.039 59.0801C177.929 58.9601 177.889 58.8001 177.909 58.6401L181.679 32.8701C181.699 32.7301 181.669 32.5801 181.579 32.4701L179.699 29.8701C179.619 29.7601 179.499 29.6901 179.369 29.6601C162.029 25.7801 165.289 10.4001 166.099 8.80009C166.909 7.19009 168.289 5.17009 167.079 2.52009C165.869 -0.149914 163.159 8.6444e-05 163.159 8.6444e-05C158.679 8.6444e-05 156.119 7.68009 156.119 13.4401C156.119 19.2001 158.109 23.4601 159.959 26.2401C161.859 29.1001 166.079 32.6401 167.359 38.4001C168.639 44.1601 165.719 51.8401 165.079 56.9601C164.439 62.0801 165.309 63.3801 166.999 64.9201C169.789 67.4701 179.269 71.8501 181.759 72.9701C182.149 73.1401 182.579 73.1401 182.959 72.9701C185.439 71.8501 194.919 67.4701 197.719 64.9201C199.409 63.3801 200.279 62.0801 199.639 56.9601C198.999 51.8401 196.079 44.1601 197.359 38.4001C198.639 32.6401 202.859 29.1001 204.759 26.2401C206.609 23.4601 208.599 19.2001 208.599 13.4401C208.599 7.68009 206.039 8.6444e-05 201.559 8.6444e-05C201.559 8.6444e-05 198.849 -0.149914 197.639 2.50009C196.429 5.15009 197.809 7.17009 198.619 8.78009C199.419 10.3901 202.679 25.7701 185.349 29.6401C185.219 29.6701 185.099 29.7401 185.019 29.8501L183.139 32.4501C183.059 32.5701 183.019 32.7101 183.039 32.8501L186.809 58.6201C186.829 58.7801 186.789 58.9401 186.679 59.0601L182.779 63.5101C182.559 63.7601 182.169 63.7601 181.949 63.5101L181.939 63.5301Z" fill="#14D675"/>
  </g>
  </svg>

      </span>
    );
  }

  export default withTheme(Logo);