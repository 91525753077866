import { WrapperStyle } from "./SelectedWorkedList.styles";
import { Col, Row, Flex, Modal } from "antd";
import { Link } from "react-router-dom";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { useState } from "react";

const SelectedWorkedList = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  const [openModalIndex, setOpenModalIndex] = useState(null);

  const handleImageClick = (index) => {
    setTimeout(() => {
      setOpenModalIndex(index);
    }, 1000); 
  };

  const handleCloseModal = () => {
    setOpenModalIndex(null);
  };

  return (
    <WrapperStyle>
    <Row gutter={[16, 16]}>
    <Flex wrap="wrap" justify={"space-between"} align={"center"} className="workDescription">
      {props?.selectedList?.map((list, index) => (
        <>
        <Col xs={24} md={12} lg={12}>
          <div className="workDescription__items" key={index}>
            <img src={list.image} alt="" onClick={() => handleImageClick(index)}/>
            <Flex align="center" justify="space-between">
              <h2>{list.title}</h2>
              {
                list.link ? <Link to={list.link} className="linkBtn" target="_blank" aria-label="Redirect to website">Visit Website</Link> : ' '
              }
              
            </Flex>
            <p>{list.dicription}</p>
          </div>
        </Col>
        <Modal
          title={list.title}
          centered
          open={openModalIndex === index}
          footer={[
            <>
              {
                list.link ? <Flex align="center" justify="center" className="modalCenterBtn"><Link to={list.link} className="linkBtn" target="_blank" aria-label="Redirect to website">Visit Website</Link></Flex> : ' '
              }
            </>
          ]}
          onCancel={handleCloseModal}
          width={1000}
        >
          {
            list.imageLarge ? (
              <img src={list.imageLarge} alt={list.altText} className="imgResponsive" />
            ) : ( 
              <p>Loading...</p>
            )
          }
          
        </Modal>
        </>
      ))}
    </Flex>
  </Row>
  </WrapperStyle>
  );
};

export default SelectedWorkedList;
