import { images } from "../../ThemeConfig/images";
import { WrapperStyle, SectionStyle, CoverStyle } from "./WhoWeAre.styles";
import { Container } from "../Container/Container";
import { Col, Row, Tag, Flex  } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Link } from "react-router-dom";
import { Constants } from "../../db/Constants";

const WhoWeAre = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperEven" : "webLayout wrapperEven";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <Tag>{Constants?.whoWeAre?.subTitle}</Tag>
        <h2>{Constants?.whoWeAre?.title}</h2>
        <CoverStyle>
          <Row gutter={[16, 16]}>
            <Flex wrap="wrap" justify={"space-between"} align={"center"} className="workDescription">
              <Col xs={24} md={24} lg={24}>
                <SectionStyle className="whoWeInfo">
                  <SectionStyle className="whoWeInfo__image">
                    <img alt='missionImg' src={images.ourMision} /> 
                  </SectionStyle>
                  
                    <SectionStyle className="whoWeInfo__content">
                      <p>{Constants?.whoWeAre?.dicription}</p>
                      <Link to={Constants?.whoWeAre?.link} className="linkBtn">{Constants?.whoWeAre?.linkText}</Link>
                    </SectionStyle>
                
                    <SectionStyle className="whoWeInfo__count">
                      <div className="CountContent">
                        {Constants?.whoWeAre?.whoWeList.map((list, index) => (
                          <div className="CountContent__numbers" key={index}>
                            <h3>{list.count}</h3>
                            <span>{list.label}</span>
                          </div>
                        ))}
                      </div>
                    </SectionStyle>
                  </SectionStyle>
              </Col>
            </Flex>
          </Row>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default WhoWeAre;
