import React from "react";

function Facebook() {
  return (
    <span className="anticon anticon-user">
      <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.42214 6.38181H10.2221L9.79991 8.0707H6.42214V15.6707H4.73325V8.0707H1.35547V6.38181H4.73325V4.80101C4.73325 3.29537 4.89031 2.74901 5.18418 2.19844C5.47214 1.65445 5.91699 1.2096 6.46098 0.921636C7.01156 0.62777 7.55791 0.470703 9.06356 0.470703C9.50436 0.470703 9.89111 0.512925 10.2221 0.59737V2.15959H9.06356C7.94551 2.15959 7.6052 2.22546 7.25813 2.41124C7.00142 2.54804 6.81058 2.73888 6.67378 2.99559C6.488 3.34266 6.42214 3.68297 6.42214 4.80101V6.38181Z" fill="white" stroke="white" strokeWidth="0.8"/>
      </svg>
    </span>
  );
}

export default Facebook;
