import React from "react";

function OpenMenu() {
  return (
    <span className="anticon anticon-user">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 17.5H4" stroke="black" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 12.5H4" stroke="black" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 7.5H4" stroke="black" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
}

export default OpenMenu;
