
import { AboutListSec } from "./Works.styles";
import WhatOurClientsSay from "../../Component/WhatOurClientsSay/WhatOurClientsSay";
import ReadyToCreate from "../../Component/ReadyToCreate/ReadyToCreate";
import Footer from "../../Component/Footer/Footer";
import OurWorks from "../../Component/OurWorks/OurWorks";
import { useEffect } from "react";

function Works() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <OurWorks/>
      <WhatOurClientsSay/>
      <ReadyToCreate/>
      <Footer/>
    </AboutListSec>
  );
}

export default Works;
