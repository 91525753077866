import { WrapperStyle, SectionStyle, CoverStyle } from "./WhatOurClientsSay.styles";
import { Container } from "../Container/Container";
import { Col, Row, Tag  } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Constants } from "../../db/Constants";
import { Carousel } from 'antd';

const WhatOurClientsSay = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  const onChange = () => {};
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <Tag>{Constants?.clientSays?.subTitle}</Tag>
        <h2>{Constants?.clientSays?.title}</h2>
          <CoverStyle>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24}>
                <SectionStyle className="cutomCarouel">
                  <Carousel effect="fade" afterChange={onChange} dots>
                  {Constants?.clientSays?.carouselList?.map((list, index) => (
                    <div className="customCarousel" key={index}>
                      <Row gutter={[16, 16]} className="reverse">
                        <Col xs={24} md={18} lg={16}>
                          <div className="info">
                            <p>{list.dicription}</p>
                            <h2>{list.name}</h2>
                            <span>{list.designation}</span>
                          </div>
                        </Col>
                        <Col xs={24} md={6} lg={8}>
                          <div>
                            <img src={list.image} alt='clientImg'/>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    ))}
                  </Carousel>
                </SectionStyle>
              </Col>
            </Row>
          </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default WhatOurClientsSay;
