import { createGlobalStyle } from 'styled-components'; 
import {  hexToRGBA  } from './ThemeConfig/Variables';
 
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Urbanist:wght@200;300;400;500;600;700&display=swap');

   * {
     box-sizing: border-box;
     padding:0;
     margin:0;
   }
  body {
    margin: 0;
    padding: 0; 
    background-color:  ${props => props.theme.WhiteBgColor}; 
    font-family: 'Inter', sans-serif;
    color: ${props => props.theme.BlackTextColor};
    scroll-behavior: smooth;
  }
  h1,h2,h3,h4,h5,h5{
    font-weight:normal;
  }
  .ant-input::placeholder {
    font-size: 14px;
    line-height: 1.42857;
    color: #333;
  }
  
  .ant-input::-ms-input-placeholder { /* Edge 12 -18 */
    font-size: 14px;
    line-height: 1.42857;
    color: #333;
  }

  .ant-select-dropdown {
    background-color: ${props => props.theme.WhiteBgColor}; 
    box-shadow:  ${props => (`0 6px 16px 0 ${hexToRGBA(`${props.theme.BlackBgColor}`, 0.08)}, 0 3px 6px -4px ${hexToRGBA(`${props.theme.BlackBgColor}`, 0.12)}, 0 9px 28px 8px ${hexToRGBA(`${props.theme.BlackBgColor}`, 0.05)}`)};
    .ant-select-item {
      &.ant-select-item-option-selected {
        color: ${props => props.theme.WhiteTextColor};
      }
    }
  }
  .ant-btn{
    font-size: 14px !important;
    font-weight:500;
    &:hover{
      background-color: ${props => props.theme.BlackTextColor} !important;
      color: ${props => props.theme.WhiteTextColor} !important;
      transition: all 0.3s;
    }
  }
  .ant-btn-primary{
    background-color: ${props => props.theme.Primary};
    color: ${props => props.theme.BlackTextColor};
  }
  .ant-btn-link{
    border: 1px solid ${props => props.theme.GrayBorderColor};
    color: ${props => props.theme.BlackTextColor};
  }
  .wrapperEven{
    background-color: #E4EFEA; 
  }
  .wrapperOdd{
    background-color: #ffffff; 
  }
  .width100{
    width:100%;
  }
  .imgResponsive{
    max-width:100%;
    vertical-align: middle;
    display: inline-block;
  }
  .linkBtnBg{
    min-width: 140px;
    padding: 15px 20px;
    text-align: center;
    background: rgb(20,214,117);
    color: #000;
    font-weight: 500;
    font-size: 14px;
    border-radius: 30px;
    margin-top: 50px;
    &:hover{
      background: #000;
      color: #ffffff;
      transition: all 0.5s ease;
    }
  }
  .linkBtn{
    min-width: 140px;
    padding: 15px 20px;
    text-align: center;
    background: #ffffff;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #cccccc;
    border-radius: 30px;
    margin-top: 50px;
    &:hover{
      background: #000;
      color: #ffffff;
      transition: all 0.5s ease;
    }
  }
  .mailLink{
    color:#14d675;
    &:hover{
      color:#14d675;
    }
    &:focue{
      color:#14d675;
    }
  }
  .ant-image-preview-root .ant-image-preview-img{
    max-height: 100vh;
  }
  .modalCenterBtn{
    .linkBtn{
      margin-top: 0;
      padding: 10px 12px;
      min-width: 125px;
    }
  }
`;
 
export default GlobalStyle;
