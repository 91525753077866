import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:100px 0 0 0;
  ${md(`
    padding:30px 0;
  `)}
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 12px;
  }
  h2{
    font-size:30px;
    line-height:40px;
    color: #000;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
    ${md(`
    font-size:28px;
    margin-bottom: 20px;
  `)}
  }
  .members{
    width:100%;
    ${md(`
        flex-wrap: wrap;
      `)}
    .ant-card{
      box-shadow:none;
    }
    &__info{
      ${md(`
        width:100%;
      `)}
      img{
        border-radius:0px;
      }
        .ant-card-body{
          padding:20px 0px;
          .ant-card-meta{
            .ant-card-meta-title{
              font-family: Urbanist, sans-serif;
              font-size: 18px;
              font-weight: 500;
              margin-bottom:0;
            }
            .ant-card-meta-description{
              font-size: 14px;
              font-weight: 400;
              color: #333;
            }
          }
        }
      
    }
  }
  
`;

export const SectionStyle = styled.div`
  width: 100%;
  p{
    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 41px;
    padding: 0px 45px;
  }
`;
export const ListStyle = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
margin:40px 0;
img{
  margin:0 27px;
}

`;

export const CoverStyle = styled.div`
  width:100%;
`;