import  Banner  from "../../Component/Banner/Banner";
import { BlogListSec } from "./Home.style";
import OurTeam from "../../Component/OurTeam/OurTeam";
import WhoWeAre from "../../Component/WhoWeAre/WhoWeAre";
import OurServices from "../../Component/OurServices/OurServices";
import LatestArticles from "../../Component/LatestArticles/LatestArticles";
import WhatOurClientsSay from "../../Component/WhatOurClientsSay/WhatOurClientsSay";
import ReadyToCreate from "../../Component/ReadyToCreate/ReadyToCreate";
import Footer from "../../Component/Footer/Footer";
import { useEffect } from "react";
function Home() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <BlogListSec className="App">
      <Banner/>
      <OurTeam/>
      <WhoWeAre/>
      <OurServices />
      <LatestArticles/>
      <WhatOurClientsSay/>
      <ReadyToCreate/>
      <Footer/>
    </BlogListSec>
  );
}

export default Home;
