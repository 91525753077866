
import { Typography } from "antd";
import { AboutListSec } from "./Careers.styles";
import ReadyToCreate from "../../Component/ReadyToCreate/ReadyToCreate";
import Footer from "../../Component/Footer/Footer";
import CareersAt from "../../Component/CareersAt/CareersAt";
import BenefitsAndPerks from "../../Component/BenefitsAndPerks/BenefitsAndPerks";
import WhyChoose from "../../Component/WhyChoose/WhyChoose";
import JobOpenings from "../../Component/JobOpenings/JobOpenings";
import { Container } from "../../Component/Container/Container";
import { useEffect } from "react";
const { Title } = Typography;

function Careers() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <Container>
        <Title level={2}>Careers</Title>
      </Container>
      <CareersAt/>
      <BenefitsAndPerks/>
      <WhyChoose/>
      <JobOpenings/>
      <ReadyToCreate/>
      <Footer/>
    </AboutListSec>
  );
}

export default Careers;
