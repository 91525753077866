import { WrapperStyle, CoverStyle } from "./JobOpenings.styles";
import { Container } from "../Container/Container";
import { Tag, Flex } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';

const JobOpenings = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
        <Tag>Careers</Tag>
        <h2>Job Openings</h2>
        <Flex wrap="wrap" justify={"space-between"} align={"center"} className="jobOpening">
          <div className="jobOpening__list">
            <p>01</p>
            <p>UI-UX Designer</p>
            <div>(04 Open Roles)</div>
            <div>India / Full Time</div>
          </div>
          <div className="jobOpening__list">
            <p>02</p>
            <p>Web Designing</p>
            <div>(06 Open Roles)</div>
            <div>India / Part Time</div>
          </div>
          <div className="jobOpening__list">
            <p>03</p>
            <p>Data Analytics</p>
            <div>(02 Open Roles)</div>
            <div>India / Full Time</div>
          </div>
          <div className="jobOpening__list">
            <p>04</p>
            <p>Brand Manager</p>
            <div>(01 Open Roles)</div>
            <div>India / Full Time</div>
          </div>
          <div className="jobOpening__list">
            <p>05</p>
            <p>Android Developer</p>
            <div>(03 Open Roles)</div>
            <div>India / Full Time</div>
          </div>
          <div className="jobOpening__list">
            <p>06</p>
            <p>Software Engineer</p>
            <div>(01 Open Roles)</div>
            <div>India / Full Time</div>
          </div>
        </Flex>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default JobOpenings;
