import { WrapperStyle, SectionStyle } from "./Footer.styles";
import { Container } from "../Container/Container";
import { Col, Row, Flex } from "antd";
import { Analytics, Instagram, Linkedin, Youtube } from "../Icon";
import { Link } from "react-router-dom";
import Facebook from "../Icon/Facebook";
import { images } from "../../ThemeConfig/images";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { useState } from "react";
import { useEffect } from "react";

const Footer = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout" : "webLayout";
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the current year in the state
    setCurrentYear(new Date().getFullYear());
  }, []);
  return (
    <WrapperStyle className={layoutClass}>
      <Analytics/>
      <Container>
        <Row gutter={[16, 16]}>
            <Col xs={24} md={10} lg={10}>
              <SectionStyle>
                <div className="footerLogo">
                  <img src={images.footerLogo} alt='footerImg' className="imgResponsive"/>
                  <span className="tagline">Digital excellence, Unified solutions</span>
                </div>
                
                <ul className="footerMenu">
                  <li>
                    <Link to="/about" aria-label="Redirect to Aboutu page">About us</Link>
                  </li>
                  <li>
                    <Link to="/projects" aria-label="Redirect to Projects page">Projects</Link>
                  </li>
                  <li>
                    <Link to="/services" aria-label="Redirect to Services page">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact" aria-label="Redirect to Contact page">Contact</Link>
                  </li>
                </ul>
              </SectionStyle>
            </Col>
            <Col xs={24} md={7} lg={7}>
              <SectionStyle>
                <div className="followUsWrapper">
                  <div className="followUs">
                    <h2>Follow us</h2>
                    <ul>
                      <li>
                        <Link to="https://www.linkedin.com/company/grratz/" target="_blank" aria-label="Redirect to Linkedin"><Linkedin/></Link>
                      </li>
                      <li>
                        <Link to="https://www.facebook.com/Grratz" target="_blank" aria-label="Redirect to Facebook"><Facebook/></Link>
                      </li>
                      <li>
                        <Link to="https://www.youtube.com/" target="_blank" aria-label="Redirect to Youtube"><Youtube/></Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/grratz.consulting/" target="_blank" aria-label="Redirect to Instagram"><Instagram/></Link>
                      </li>
                    </ul>
                  </div>
                  <div className="followAddress">
                    <p>&nbsp;</p>
                  </div>
                </div>
              </SectionStyle>
            </Col>
            <Col xs={24} md={7} lg={7}>
              <SectionStyle>
                <div className="contactInfoWrapper">
                  <div className="contactInfo">
                    <h2>Contact info</h2>
                    <ul>
                      <li>
                        <Link to="tel:+91 9958146091">hello@grratz.com</Link>
                      </li>
                      <li>
                        <Link to="mailto:hello@grratz.com1">A-4/3 Second Floor, Sector-80, Phase-II, Noida (201301), Uttar Pradesh. India</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </SectionStyle>
            </Col>
            <Flex wrap="wrap" justify={"space-between"} align={"center"} className="footerCopyright">
              <div className="copyInfo">Copyright © {currentYear} Grratz. All Rights Reserved.</div>
              <ul className="copyMenu">
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
              </ul>
            </Flex>
        </Row>
      </Container>
    </WrapperStyle>
  );
};

export default Footer;
