import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:50px 0 150px 0;
  ${md(`
    padding:30px 0;
  `)}
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 12px;
  }
  h2{
    font-size:30px;
    line-height:40px;
    color: #000;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
    ${md(`
    font-size:28px;
    margin-bottom: 20px;
  `)}
  }
  .faqWrapper{
    width:100%;
    &__info{
      margin-left:50px;
      ${md(`
      margin: 30px 0 0 0;
      `)}
      .ant-collapse-item{
        background:#ffffff;
        .ant-collapse-header{
          padding:25px 35px 25px 0;
          .ant-collapse-expand-icon{
            position: absolute;
            right: 0;
          }
          .ant-collapse-header-text{
            font-size: 18px;
            font-weight: 500;
            color: #000000;
          }
        }
        .ant-collapse-content-active{
          .ant-collapse-content-box{
            padding: 0 0 20px 0;
            p{
              font-size: 16px;
              font-weight: 400;
              color: #333;
            }
          }
        }
      }
      
    }
  }
  
`;

export const SectionStyle = styled.div`
  width: 100%;
  p{
    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 41px;
    padding: 0px 45px;
  }
`;
export const ListStyle = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
margin:40px 0;
img{
  margin:0 27px;
}
`;

export const CoverStyle = styled.div`
  width: 100%;
`;
