// Styled Component Variables
export const customTheme = (themeColor) => ({
  Primary: themeColor.Primary,
  Secondary: themeColor.Secondary,
  WhiteBgColor: themeColor.WhiteBgColor,
  WhiteTextColor: themeColor.WhiteTextColor,
  BlackBgColor: themeColor.BlackBgColor,
  BlackTextColor: themeColor.BlackTextColor,
  GrayBorderColor: themeColor.GrayBorderColor, 
  GreenBgColor: themeColor.GreenBgColor,
  GreenTextColor: themeColor.GreenTextColor,
});

// AntDesign global Variables
export const antDTheme = (themeColor) => ({
  colorPrimary: themeColor.Primary,
  colorPrimaryBg: themeColor.Primary,
  colorPrimaryBgHover: themeColor.Secondary,
  controlHeightSX: 30,
  controlHeightSM: 40,
  controlHeightLG: 50,
  fontSize: 16,
  fontFamily: '"Inter", sans-serif',
  borderRadius: 8,
  colorBgContainer: themeColor.WhiteBgColor,
  colorTextHeading: themeColor.BlackTextColor,
});

//AntDesign Components Variables
export const antDComponents = (themeColor) => ({
  Button: {
    sizeStep: 10,
    paddingContentHorizontal: 20,
    colorBorder: themeColor.Primary,
    colorBgTextHover: themeColor.Secondary,
    colorBgTextActive: themeColor.Secondary,
    controlHeight: 40,
    controlHeightSM: 30,
    colorBorderSecondary: themeColor.Secondary,
    colorPrimaryText: themeColor.Primary,
    paddingXS: 20,
    borderRadius: 50,
    borderRadiusLG: 50,
    colorBgContainer:themeColor.WhiteBgColor,
    colorPrimary: themeColor.GreenBgColor,
    colorPrimaryText: themeColor.WhiteTextColor,
  },
  Select: {
    fontWeightStrong: 400,
    colorBorder: themeColor.GrayBorderColor,
    colorTextPlaceholder: themeColor.BlackTextColor,
    colorTextDisabled: themeColor.BlackTextColor,
    colorText: themeColor.BlackTextColor,
    fontSize: 14,
    colorTextQuaternary: themeColor.BlackTextColor
  },
  Card: {
    colorBorderSecondary: themeColor.GrayBorderColor,
  },
  Layout: {
    headerBg: themeColor.WhiteBgColor,
    bodyBg: themeColor.WhiteBgColor,
  },
  Menu: {
    menuHorizontalHeight: 40,
    itemColor: themeColor.BlackTextColor,
    itemHoverColor: themeColor.Primary,
    itemBg: themeColor.WhiteBgColor,
    motionDurationSlow:0,
  }
});

// hex to rgba converter 
 export function hexToRGBA(hex, opacity) {
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
}
 
// Media Query

export const sm = content => `
@media (max-width: 575px) {
  ${content};
}
`
export const md = content => `
@media (max-width: 767px) {
  ${content};
}
`
export const lg = content => `
@media (max-width: 991px) {
  ${content};
}
`
export const xl = content => `
@media (max-width: 1199px) {
  ${content};
}
`