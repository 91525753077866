
import { Flex, Typography } from "antd";
import { AboutListSec } from "./AnalyticsReporting.styles";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import { ButtonStyle } from "../../Component/Button/Button.style";
const { Title } = Typography;

function AnalyticsReporting() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Elevate Your Business with Actionable Insights from Grratz Analytics and Reporting Service</Title>
          <div className="serviceContenet">
          <p className="content">Unlock the power of data-driven decision-making with Grratz's Analytics and Reporting Service. We offer comprehensive solutions to help businesses of all sizes harness the full potential of their data, gain valuable insights, and drive strategic growth.</p>
          <div className="list">
          <Title level={3}>Our Services:</Title>
            <ul>
            <Title level={5}>Data Analysis:</Title>
              <li>We analyze your data to uncover hidden patterns, trends, and correlations, providing you with valuable insights into your business operations, customer behavior, and market trends.</li>
              <Title level={5}>Customized Reporting:</Title>
              <li>Our team creates customized reports and dashboards tailored to your specific needs, allowing you to track key performance indicators (KPIs), monitor progress towards goals, and make informed decisions.</li>
              <Title level={5}>Performance Monitoring:</Title>
              <li>Stay informed in real-time with our performance monitoring solutions. We set up automated alerts and notifications to keep you updated on critical metrics and deviations from expected outcomes.</li>
              <Title level={5}>Predictive Analytics:</Title>
              <li>Leverage predictive analytics to forecast future trends, anticipate customer needs, and identify opportunities for growth. Our advanced models and algorithms help you make proactive decisions and stay ahead of the competition.</li>
              <Title level={5}>Data Visualization:</Title>
              <li>We transform complex data into easy-to-understand visualizations such as charts, graphs, and heatmaps, making it simple for you to interpret and communicate insights across your organization.</li>
            </ul>
          </div>

          <div className="list">
          <Title level={3}>Why Choose Grratz?</Title>
            <ul>
              <Title level={5}>Expertise and Experience:</Title>
              <li>Our team of data scientists and analysts has extensive experience in analyzing data across various industries, delivering actionable insights that drive business success.</li>
              <Title level={5}>Customized Solutions:</Title>
              <li>We understand that every business is unique. That's why we tailor our services to your specific needs, ensuring that you get the insights and reports that matter most to your organization.</li>
              <Title level={5}>Scalability and Flexibility:</Title>
              <li>Whether you're a small startup or a large enterprise, our solutions are scalable and flexible to accommodate your evolving business needs and growth objectives.</li>
              <Title level={5}>Dedicated Support:</Title>
              <li>We're committed to your success. Our team provides ongoing support and guidance, helping you interpret insights, optimize processes, and maximize the value of your data investment.</li>
            </ul>
          </div>

          <div className="titleText">
            <Title level={2}>Get Started Today!</Title>
            <p>Ready to transform your business with data-driven insights? Contact us today to learn more about Grratz's Analytics and Reporting Service and take the first step towards unlocking the full potential of your data</p>
          </div>
           <Link to="mailto:hello@grratz.com" className="mailLink">hello@grratz.com</Link>
          <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" href="/contact" shape="round" size={'large'}>Contact Us</ButtonStyle>
            </Flex>
          </div>
          <Faq/>
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default AnalyticsReporting;
