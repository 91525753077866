import styled from "styled-components";
import { Link } from "react-router-dom";
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:150px 0;
  ${lg(`
    padding:60px 0;
  `)} 
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 12px;
  }
  h2{
    font-size:30px;
    line-height:40px;
    color: #000;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
    ${md(`
      margin-bottom: 0;
    `)} 
  }
  .ourServices{
    width:100%;
    &__cards{
      display:flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #CCC;
      height:390px;
      padding:44px;
      margin-bottom:16px;
      ${lg(`
        height:auto;
        padding:30px;
        flex-direction: column;
      `)}
      .iconCount{
        min-height:100%;
        max-height:100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        font-size:22px;
        font-weight:500;
        color: #000;
        font-family: 'Urbanist', sans-serif;
        ${lg(`
          flex-direction: row;
          width:100%;
          min-height:auto;
          max-height:auto;
        `)}
        p{
          font-size: 22px;
          font-weight: 500;
          color: #000;
          ${lg(`
            color: #666;
          `)}
        }
        img{
          width: 120px;
          height: 120px;
          ${lg(`
            width:80px;
            height:80px;
          `)}
        }
      }
      .infoMore{
        min-height:100%;
        max-height:100%;
        display:flex;
        margin-left:81px;
        flex-direction: column;
        justify-content: space-between;
        ${lg(`
          width:100%;
          min-height:auto;
          max-height:auto;
          margin:50px 0 0 0;
        `)}
        h4{
          font-size:22px;
          font-weight:500;
          color: #000;
          font-family: 'Urbanist', sans-serif;
        }
        p{
          margin-top:54px;
          font-size:16px;
          font-weight:400;
          line-height:27px;
          color: #333;
          ${lg(`
            margin:15px 0 40px 0;
            font-size:15px;
          `)}
        }
        a{
          font-size:14px;
          font-weight:500;
          line-height:27px;
          color: #333;
          .anticon{
            margin-left:10px;
          }
        }
      }
    }
  }
  .ant-btn-link{
    margin-top:30px;
  }
`;

export const SectionStyle = styled.div`
  width: 100%;
`;

export const LinkStyle = styled(Link)`
    display: flex;
    .anticon{
      svg{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-in;
      }
    }
    &:hover .anticon{
      svg{
        transform: translate3d(4px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-out;
      }
    }
`;

export const CoverStyle = styled.div`
.width100{
  ${xl(`
    margin-right:10px;
  `)}
}

`;