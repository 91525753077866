import { images } from "../../ThemeConfig/images";
import { Container } from "../../Component/Container/Container";
import { LinkArrow, WhitePlay } from "../Icon";
import { WrapperStyle, BannerImgStyle, BannerInfoStyle, NetworkStyle, SectionStyle, AboutStyle, DescriptionStyle, LinkStyle, TitleStyle, CoverStyle} from "./Banner.styles";
import { Col, Row, Divider, Avatar, Carousel } from "antd";
import React from 'react';
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Link } from "react-router-dom";
import { Constants } from "../../db/Constants";

function Banner() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperEven" : "webLayout wrapperEven";
  const onChange = () => {};
  return (
    <WrapperStyle className={layoutClass}>
     <Container>
      <CoverStyle>
        <Row gutter={[55, 16]} className="reverse">
            <Col xs={24} md={12} lg={11}>
              <BannerImgStyle>
                <img alt="HeroImg" src={Constants?.siteBanner?.image} />
                <span className="videoIcon"><WhitePlay/></span>
              </BannerImgStyle>
              <NetworkStyle>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12} lg={10} xl={8}>
                    <SectionStyle className="groupAvatar">
                      <TitleStyle>{Constants?.siteBanner?.contctHeading}</TitleStyle>
                      <Avatar.Group>
                        <Avatar src={images.userOne} size={46} alt="UserAvatar"></Avatar>
                        <Avatar src={images.userTwo} size={46} alt="UserAvatar"></Avatar>
                        <Link to="/careers" aria-label="Redirect to Career Page">
                          <Avatar size={46} className="filledAvatar">
                            <LinkArrow/>
                          </Avatar>
                        </Link>
                      </Avatar.Group>
                    </SectionStyle>
                  </Col>
                  <Col xs={24} md={12} lg={14} xl={16}>
                    <div className="bannerCarousel">
                      <Carousel afterChange={onChange} dots={false} autoplay effect="fade">
                        <div className="carouselItem">
                          <div className="count">
                            <h2>14+</h2>
                            <span>Years of Experiences</span>
                          </div>
                          <div className="info">
                            <p>Design Innovation</p>
                          </div>
                        </div>
                        <div className="carouselItem">
                          <div className="count">
                            <h2>80+</h2>
                            <span>Completed Project</span>
                          </div>
                          <div className="info">
                            <p>Design Innovation</p>
                          </div>
                        </div>
                        <div className="carouselItem">
                          <div className="count">
                            <h2>2K+</h2>
                            <span>Happy Customer</span>
                          </div>
                          <div className="info">
                            <p>Design Innovation</p>
                          </div>
                        </div>
                      </Carousel>
                    </div>
                  </Col>
                </Row>
              </NetworkStyle>
            </Col>
            <Col xs={24} md={12} lg={13}>
              <BannerInfoStyle>
                <h1>{Constants?.siteBanner?.heading}</h1>
                {/* <ButtonStyle type="primary" size={'large'} href="/works">See Our Work</ButtonStyle> */}
                <Link to={Constants?.siteBanner?.link} className="linkBtnBg">{Constants?.siteBanner?.linkLabel}</Link>
              </BannerInfoStyle>
              <Divider />
              <AboutStyle>
                  <DescriptionStyle>{Constants?.siteBanner?.decription}</DescriptionStyle>
                  <LinkStyle to={Constants?.siteBanner?.aboutLink}>{Constants?.siteBanner?.aboutLinkLabel}<LinkArrow/> </LinkStyle>
              </AboutStyle>
            </Col>
        </Row>
      </CoverStyle>
     </Container>
    </WrapperStyle>
  );
}
export default Banner;
