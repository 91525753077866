import styled from "styled-components";
import { hexToRGBA } from "../../ThemeConfig/Variables";
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:150px 0;
  ${md(`
      padding:50px 0 80px 0;
  `)} 
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 12px;
  }
  h2{
    font-size:30px;
    line-height:40px;
    color: #000;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
    ${md(`
      margin-bottom: 20px;
    `)} 
}
`;

export const SectionStyle = styled.div`
  width: 100%;
  /* .customOwl{
    .item{
      .reverse{
        ${md(`
          flex-direction: column-reverse;
        `)} 
      }
      .info{
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        p{
          font-size:26px;
          line-height:39px;
          font-weight:400;
          color:#333;
          padding-right:20px;
          ${lg(`
            font-size: 18px;
            line-height: 26px;
          `)}
          ${md(`
            font-size: 16px;
            line-height: 26px;
          `)}
        }
        h5{
          font-size:16px;
          line-height:20px;
          font-weight:400;
          color:#333;
          margin:40px 0 10px 0;
          ${lg(`
            margin:20px 0 5px 0;
          `)}
        }
        span{
          font-size:16px;
          line-height:20px;
          font-weight:400;
          color:#333;
          ${md(`
            font-size:14px;
          `)}
        }
      }
    }
    .ant-carousel{
      .slick-slider{
        .slick-list{

        }
        .slick-dots{
          li{
            button{
              background-color: #f00;
            }
          }
        }
      }
    }
  } */
  .customCarousel{
    .info {
      height: 100%;
      display: flex;
      align-items: flex-start;
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
    p {
      font-size: 26px;
      line-height: 39px;
      font-weight: 400;
      color: rgb(51, 51, 51);
      padding-right: 20px;
      ${md(`
       font-size:18px;
       line-height:30px;
       padding: 0px;
      `)}
    }
    h2 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: rgb(51, 51, 51);
        margin: 40px 0px 10px;
        ${md(`
          font-size: 20px;
          font-weight:700;
        `)}
    }
    span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: rgb(51, 51, 51);
    }
  }
  }
  .ant-carousel{
      .slick-slider{
        .slick-list{

        }
        .slick-dots{
          justify-content: flex-end;
          li{
            button{
              height: 10px;
              width: 10px;
              background-color: rgb(20,214,117);
              border-radius: 50%;
            }
            &.slick-active{
              width: 16px;
            }
          }
        }
        img{
          width: 100%;
          max-height: 500px;
          min-height: 500px;
          object-fit: cover;
        }
      }
      .slick-dots-bottom{
        bottom: -25px;
      }
    }
`;

export const CoverStyle = styled.div`
   width:100%
`;