import React from "react";

function FaqRemove() {
  return (
    <span className="anticon FaqRemove">
      <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path class="cls-1" d="M15.5,7.5v1H.5v-1Z"/></svg>
    </span>
  );
}

export default FaqRemove;
