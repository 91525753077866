
import { Flex, Typography } from "antd";
import { AboutListSec } from "./ServiceDescription.styles";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import { ButtonStyle } from "../../Component/Button/Button.style";
const { Title } = Typography;

function ServiceDescription() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <div>
        <Container>
          <Title level={2}>Analytics & Reporting</Title>
          <div className="serviceContenet">
          <p className="content">Software that meets your customers' needs fosters loyalty and satisfaction. Enhance your relationships with clients through software designed with their preferences in mind. Custom software typically requires less ongoing maintenance compared to off-the-shelf solutions, saving you time and resources. Software development can be a strategic asset for your business. It enables you to innovate and create new revenue streams, opening doors to growth opportunities.</p>
          <p className="content">Embracing software development can be a game-changer for businesses of all sizes. At Pixlab, we understand the profound impact that custom software solutions can have on your organization. Here are some of the key benefits you can expect when you choose our Software Development service.</p>
          <div className="list">
            <ul>
              <li>Tailored Solutions: Get software that fits your business like a glove.</li>
              <li>Competitive Advantage: Gain an edge with custom features and functionalities.</li>
              <li>Efficiency: Automate processes and streamline operations.</li>
              <li>Scalability: Expand your software as your business grows.</li>
              <li>Security: Protect your data and user information.</li>
            </ul>
          </div>
          <div className="titleText">
            <Title level={2}>Start Your Software Evolution</Title>
            <p>Take the first step towards software evolution that enhances your business capabilities. Our custom software solutions are designed to take your operations to the next level. Whether you're looking to streamline processes, boost efficiency, or innovate in your industry, we're here to help. Contact us today to embark on a journey of transformation and growth.</p>
          </div>
          <Flex justify={"flex-start"} align={"center"} className="width100 link">
              <ButtonStyle type="link" href="/contact" shape="round" size={'large'}>Contact Us</ButtonStyle>
            </Flex>
          </div>
          <Faq/>
        </Container>
      </div>
      <Footer/>
    </AboutListSec>
  );
}

export default ServiceDescription;
