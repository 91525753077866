import React from "react";

function FaqAdd() {
  return (
    <span className="anticon faqAdd">
      <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path className="cls-1" d="M.5,8.5v-1h15v1Z"/><path className="cls-1" d="M8.5,15.5h-1V.5h1Z"/></svg>
    </span>
  );
}

export default FaqAdd;
