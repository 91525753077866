import styled from "styled-components";
import { Link } from "react-router-dom";
import { hexToRGBA } from "../../ThemeConfig/Variables";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  margin:40px 0 0 0;
  background:#E4EFEA;
  padding:80px 0;
  ${md(`
    padding:30px 0;
  `)}
  .information{
    width:100%;
    &__info{
      margin-left:50px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ${md(`
        margin: 20px 0 0 0;
        height:200px;
      `)}
      .blogTitle{
        p{
          font-size: 14px;
          font-weight: 400;
          color: #333;
          margin-bottom: 15px;
        }
        h2{
          font-size: 28px;
          font-weight: 500;
          color: #000;
        }
      }
      .blogMore{
        a{
          font-size: 14px;
          font-weight: 400;
          color: #333;
          .anticon{
            margin-left:10px;
          }
        }
      }
    }
    &__image{
      position:relative;
      height:100%;
      overflow: hidden;
      img{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-in;
        width: 100%;
        max-height: 420px;
        min-height: 420px;
        object-fit: cover;
      }
      &:hover{
        img{
          transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1);
          transform-style: preserve-3d;
          transition: all 0.5s ease-out;
        }
      }
    }
  }
`;

export const SectionStyle = styled.div`
  width: 100%;
  p{
    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 41px;
    padding: 0px 45px;
  }
`;
export const ListStyle = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
margin:40px 0;
img{
  margin:0 27px;
}

`;

export const LinkStyle = styled(Link)`
    display: flex;
    .anticon{
      svg{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-in;
      }
    }
    &:hover .anticon{
      svg{
        transform: translate3d(4px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-out;
      }
    }
`;

export const CoverStyle = styled.div`
  width:100%;
`;