import { WrapperStyle, CoverStyle } from "./LatestArticles.styles";
import { Container } from "../Container/Container";
import { Tag, Flex, Card  } from "antd";
import { Link } from "react-router-dom";
import { LinkArrow } from "../Icon";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Constants } from "../../db/Constants";
const { Meta } = Card;

const LatestArticles = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperEven" : "webLayout wrapperEven";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
            <Flex wrap="wrap" justify={"space-between"} align={"center"} className="latestArticles">
              <div className="articleWrapper">
                  <div className="articleWrapper__title">
                    <Tag>{Constants?.latestArticles?.subTitle}</Tag>
                    <h2>{Constants?.latestArticles?.title}</h2>
                  </div>
                  <div className="latestArticles__wrap">
                    {Constants?.latestArticles?.articlesList?.map((list, index) => (
                      <div className="articlesCard" key={index}>
                        <Link  to={list.linkTitle}>
                          <Card
                            hoverable={false}
                            cover={<img src={list.image} alt='articalImg'/>}
                          >
                            <Meta title={<div>{list.date}</div>} description={<div><p>{list.title}</p><Link to={list.linkTitle}>{list.articalLink}<LinkArrow/></Link></div>} />
                          </Card>
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div className="articleWrapper__btn">
                    <Link to={Constants?.latestArticles?.link} className="linkBtn">{Constants?.latestArticles?.moreArticles}</Link>
                  </div>
              </div>
              <div className="latestArticles__post">
                <div className="postImage">
                  <Link to={Constants?.latestArticles?.link}>
                    <img src={Constants?.latestArticles?.image} alt='latestImg'/>
                    <div className="postImage__info">
                      <span>{Constants?.latestArticles?.imageDate}</span>
                        <Flex justify={"space-between"} align={"center"} className="imageList">
                          <h2>{Constants?.latestArticles?.imageTitle}</h2>
                          <p><LinkArrow/></p>
                        </Flex>
                    </div>
                  </Link>
                </div>
              </div>
            </Flex>
          </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default LatestArticles;
