import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:0 0 100px 0;
  ${md(`
    padding:30px 0;
  `)}
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 12px;
  }
  h2{
    font-size:30px;
    line-height:40px;
    color: #000;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
    ${md(`
    font-size:28px;
    margin-bottom: 20px;
  `)}
  }
  .statics{
    width:100%;
    &__count{
      ${md(`
      width:100%;
      height:150px;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-bottom:1px solid #f1f1f1;
    `)}
      h2{
        color: #000;
        margin-bottom: 2px;
        font-family: Urbanist, sans-serif;
        font-size: 56px;
        font-weight: 500;
        margin-bottom:10px;
      }
      p{
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  
`;

export const SectionStyle = styled.div`
  width: 100%;
  p{
    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 41px;
    padding: 0px 45px;
  }
`;
export const ListStyle = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
margin:40px 0;
img{
  margin:0 27px;
}
`;

export const CoverStyle = styled.div`
  width: 100%;
`;