import styled from "styled-components";
import { Link } from "react-router-dom";
import { lg } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  ${lg(`
    padding:60px 0;
  `)} 
  .ourServices{
    width:100%;
    &__cards{
      display:flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #CCC;
      height:390px;
      padding:44px;
      margin-bottom:16px;
      ${lg(`
        height:auto;
        padding:30px;
        flex-direction: column;
      `)}
      .iconCount{
        min-height:100%;
        max-height:100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        font-size:22px;
        font-weight:500;
        color: #000;
        font-family: 'Urbanist', sans-serif;
        ${lg(`
          flex-direction: row;
          width:100%;
          min-height:auto;
          max-height:auto;
        `)}
        p{
          font-size: 22px;
          font-weight: 500;
          color: #000;
          ${lg(`
            color: #666;
          `)}
        }
        img{
          width: 120px;
          height: 120px;
          ${lg(`
            width:80px;
            height:80px;
          `)}
        }
      }
      .infoMore{
        min-height:100%;
        max-height:100%;
        display:flex;
        margin-left:81px;
        flex-direction: column;
        justify-content: space-between;
        position:relative;
        ${lg(`
          width:100%;
          min-height:auto;
          max-height:auto;
          margin:50px 0 0 0;
        `)}
        h2{
          font-size:22px;
          font-weight:500;
          color: #000;
          font-family: 'Urbanist', sans-serif;
        }
        p{
          margin-top:54px;
          font-size:16px;
          font-weight:400;
          line-height:27px;
          color: #333;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          ${lg(`
            margin:15px 0 40px 0;
            font-size:15px;
          `)}
        }
        a{
          font-size:14px;
          font-weight:500;
          line-height:27px;
          color: #333;
          .anticon{
            margin-left:10px;
          }
        }
        .topServices{
          position: absolute;
          top: -25px;
          right: 0;
          font-size:12px;
          color:#000;
          img{
            width: 15px;
            position: absolute;
            top: 0;
            right: 4.5rem;
            border:unset;
          }
        }
      }
    }
  }
`;

export const LinkStyle = styled(Link)`
    display: flex;
    .anticon{
      svg{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-in;
      }
    }
    &:hover .anticon{
      svg{
        transform: translate3d(4px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-out;
      }
    }
`;
