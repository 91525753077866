import { WrapperStyle, CoverStyle } from "./ReadyToCreate.styles";
import { Container } from "../Container/Container";
import { Col, Row } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { Link } from "react-router-dom";
import { Constants } from "../../db/Constants";

const ReadyToCreate = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperEven" : "webLayout wrapperEven";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
          <Row gutter={[16, 16]}>
              <Col xs={24} md={10} lg={8}>
              <div className="createInfo">
                <h3>{Constants?.readyToCreate?.title}</h3>
                <p>{Constants?.readyToCreate?.subTitle}</p>
                <img src={Constants?.readyToCreate?.icon} alt='readyImg'/>
                <Link to="/contact" className="linkBtn" aria-label="Redirect to get started page">Get Started</Link>
              </div>
              </Col>
              <Col xs={24} md={14} lg={16}>
                <div className="createImage">
                  <img src={Constants?.readyToCreate?.image} alt='createImg'/>
                </div>
              </Col>
          </Row>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default ReadyToCreate;
