import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";


export const AboutListSec = styled.section`
    width: 100%;
    h2.ant-typography{
        font-family: Urbanist, sans-serif;
        font-size: 48px;
        font-weight: 400;
        color:#000;
        margin:50px 0 30px 0;
        ${md(`
          font-size: 35px;
          margin:30px 0;
      `)}
    }
    .serviceContenet{
        .ant-typography{
            margin:40px 0 0 0;  
        }
        .content{
            margin:0 0 18px 0;
            font-size: 16px;
            line-height:25px;
            font-weight: 400;
        }
        .list{
            margin-bottom:15px;
            h3.ant-typography{
                margin:0 0 15px 0;  
            }
             h5.ant-typography{
                margin:0 0 5px -15px;  
            }
            ul{
                margin: 0 0 0 30px;
                padding:0;
                li{
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom:20px;
                    line-height:25px;
                }
            }
        }
        .titleText{
            .ant-typography{
                margin:50px 0 0 0;  
            }
            p{
                margin:15px 0 18px 0;
                font-size: 16px;
                line-height:25px;
                font-weight: 400;
            }
           
        }
        .link{
            margin:40px 0 80px 0;
        }
    }
`