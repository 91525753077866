import React from "react";

function WhitePlay() {
  return (
    <span className="anticon anticon-user">
      <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 17.1429V0L12.6984 8.57143L0 17.1429Z" fill="white"/>
      </svg>
    </span>
  );
}

export default WhitePlay;
