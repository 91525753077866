export const ThemeMode = { 
    Default:  {
        Primary: '#14d675',
        Secondary: '#E4EFEA',
        WhiteBgColor: '#ffffff',
        WhiteTextColor: '#ffffff',
        BlackBgColor: '#000000',
        BlackTextColor: '#000000',
        MenuTextColor: '#0C0C0C',
        CommanTextColor: '#333',
        BtnBgColor: '#212121',
        GrayBorderColor: '#ccc',
        FooterTextColor: '#999',
    },
    DarkMode:  {
        Primary: '#b582ff',
        Secondary: '#F9EACE',
        WhiteBgColor: '#1e2b42',
        WhiteTextColor: '#ffffff',
        BlackBgColor: '#ffffff',
        BlackTextColor: '#ffffff',
        MenuTextColor: '#0C0C0C',
        CommanTextColor: '#333',
        BtnBgColor: '#212121',
        GrayBorderColor: '#465570',
        FooterTextColor: '#999',
    },
}