import { images } from "../../ThemeConfig/images";
import { WrapperStyle, CoverStyle } from "./CareersAt.styles";
import { Container } from "../Container/Container";
import { Col, Row, Flex, Typography  } from "antd";
import { ButtonStyle } from "../Button/Button.style";
import useMediaQuery from '../../Hooks/useMediaQuery';
const { Title, Paragraph } = Typography;

const CareersAt = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
        <Row gutter={[16, 16]}>
          <Flex wrap="wrap" justify={"space-between"} align={"center"} className="workDescription">
            <Col xs={24} md={12} lg={12}>
              <div className="workDescription__info">
              <Title level={2}>Careers at Grratz</Title>
                <Paragraph>
                Welcome to Career page, where you can discover a world of opportunities and join our dynamic team. At Grratz, we are committed to fostering talent, innovation, and growth.
                </Paragraph>
                <ButtonStyle type="link" shape="round" size={'large'}>Join us</ButtonStyle>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="workDescription__img">
                <img alt="CareerImg" src={images.careersatgrratz} className="imgResponsive"/>
              </div> 
            </Col>
          </Flex>
        </Row>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default CareersAt;
