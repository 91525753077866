import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  margin:40px 0 0 0;
  ${md(`
    margin:0;
  `)}
  h2.ant-typography{
    font-family: Urbanist, sans-serif;
    font-size: 48px;
    font-weight: 400;
    color:#000;
    margin:30px 0 50px 0;
    ${md(`
    font-size:35px;
    margin: 60px 0 0 0;
  `)}
  }
`;
