
import { Typography } from "antd";
import { AboutListSec } from "./Blogs.styles";
import Footer from "../../Component/Footer/Footer";
import InformativeArticles from "../../Component/InformativeArticles/InformativeArticles";
import BlogList from "../../Component/BlogList/BlogList";
import { Container } from "../../Component/Container/Container";
import { useEffect } from "react";
const { Title } = Typography;

function Blogs() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <Container>
        <Title level={2}>Read Our Informative Articles</Title>
      </Container>
      <InformativeArticles/>
      <BlogList/>
      <Footer/>
    </AboutListSec>
  );
}

export default Blogs;
