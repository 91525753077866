import styled from "styled-components";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  ${md(`
    padding:30px 0;
  `)}
  .workDescription{
    width:100%;
    &__items{
      margin-bottom:15px;
      overflow: hidden;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      h2{
        font-size:22px;
        font-weight:500;
        font-family: 'Urbanist', sans-serif;
        margin: 18px 0 5px 0;
        color: #000;
      }
      p{
        font-size:14px;
        line-height:20px;
        font-weight:400;
        color: #333;
        height:40px;
        display: -webkit-box; 
        -webkit-box-orient: vertical; 
        -webkit-line-clamp: 2; 
        overflow: hidden; 
        text-overflow: ellipsis; 
      }
      img{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-in;

        width: 100%;
        max-height: 460px;
        min-height: 460px;
        object-fit: cover;
        &:hover{
            transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1);
            transform-style: preserve-3d;
            transition: all 0.5s ease-out;
            cursor: pointer;
        }
      }
      .linkBtn{
        min-width: 118px;
        padding: 10px 12px;
        margin: 12px 0;
      }
    }
  }
`;
