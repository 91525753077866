import { WrapperStyle, CoverStyle, LinkStyle } from "./InformativeArticles.styles";
import { Container } from "../Container/Container";
import { Col, Row, Flex } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { LinkArrow } from "../Icon";
import { Constants } from "../../db/Constants";

const InformativeArticles = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  return (
      <WrapperStyle>
      <Container>
        <CoverStyle>
        <Row gutter={[16, 16]}>
          <Flex wrap="wrap" justify={"space-between"} align={"center"} className="information">
            <Col xs={24} md={12} lg={12}>
              <div className="information__image">
                <img src={Constants?.informativeArticles?.imageHero} alt='articleImg' className="imgResponsive"/>
              </div> 
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="information__info">
                <div className="blogTitle">
                  <p>{Constants?.informativeArticles?.date}</p>
                  <h2>{Constants?.informativeArticles?.dicription}</h2>
                </div>
                <div className="blogMore">
                  <LinkStyle to={Constants?.informativeArticles?.linkTitle}>{Constants?.informativeArticles?.linkText}<LinkArrow/></LinkStyle>
                </div>
              </div>
            </Col>
          </Flex>
        </Row>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default InformativeArticles;
