import styled from "styled-components";
import { Link } from "react-router-dom";
import { md } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:100px 0 0 0;
  ${md(`
    padding:30px 0;
  `)}
  .ant-tag{
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    font-size:12px;
    font-weight:500;
    padding:6px 12px;
    line-height:16px;
    margin-bottom: 12px;
  }
  h2{
    font-size:30px;
    line-height:40px;
    color: #000;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 40px;
    ${md(`
    font-size:28px;
    margin-bottom: 20px;
  `)}
  }
  .contactus{
    width:100%;
    &__info{
      margin-right:50px;
      .contactForm{
        .ant-input{
          font-size:14px;
          color: #333;
          border:none;
          border-radius:0;
          box-shadow:unset;
          border-bottom: 1px solid rgba(0, 0, 0, .2);
        }
        .submitBtn{
          display:flex;
          align-items: center;
          justify-content: flex-end;
          margin-top:30px;
        }
        .contactBtn{
          font-size:14px;
          font-weight: 500;
          height:50px;
          width:100px;
          border:1px solid rgb(204, 204, 204);
          border-radius: 50px;
          background-color: #ffffff;
          color: #000000;
          margin-top: 2rem;
          &:hover{
            transition: all 0.5s ease;
            background-color: #000000;
            color: #ffffff;
            cursor: pointer;
          }
        }
        input:-internal-autofill-selected {
          background-color: transparent !important;
        }
        .customFormFild{
          width: 100%;
          display: flex;
          flex-direction: column;
          .formLabel{
            margin-bottom: 0.5rem;
            padding-left: 10px;
            position: relative;
            &::before{
              content:'*';
              font-size: 24px;
              color: #f00;
              position: absolute;
              left: 0;
            }
          }
          .formField{
            height:40px;
            padding:0.5rem;
            border: none;
            border-bottom: 1px solid #000;
            &:focus{
              outline: unset;
            }
          }
        }
      }
    }
  }
  
`;

export const SectionStyle = styled.div`
  width: 100%;
  p{
    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 41px;
    padding: 0px 45px;
  }
`;
export const ListStyle = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
margin:40px 0;
img{
  margin:0 27px;
}

`;

export const LinkStyle = styled(Link)`
    display: flex;
    .anticon{
      svg{
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-in;
      }
    }
    &:hover .anticon{
      svg{
        transform: translate3d(4px, 0px, 0px) scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: all 0.5s ease-out;
      }
    }
`;

export const CoverStyle = styled.div`
  width: 100%;
`;