
import { AboutListSec } from "./About.styles";
import Aboutus from "../../Component/About/About";
import HowWeWork from "../../Component/HowWeWork/HowWeWork";
import OurServices from "../../Component/OurServices/OurServices";
import Counts from "../../Component/Counts/Counts";
import MeetOurTeam from "../../Component/MeetOurTeam/MeetOurTeam";
import WhatOurClientsSay from "../../Component/WhatOurClientsSay/WhatOurClientsSay";
import ReadyToCreate from "../../Component/ReadyToCreate/ReadyToCreate";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <Aboutus />
      <HowWeWork/>
      <OurServices/>
      <Counts/>
      <MeetOurTeam/>
      <WhatOurClientsSay/>
      <Faq/>
      <ReadyToCreate/>
      <Footer/>
    </AboutListSec>
  );
}

export default About;
