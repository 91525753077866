
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import Footer from "../../Component/Footer/Footer";
import { AboutListSec } from "./Terms.styles";
import {Typography } from "antd";
const { Title, Paragraph } = Typography;

function Terms() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
    <AboutListSec>
    <Container>
        <Title level={2}>Grratz Consulting Pvt. Ltd. Terms and Conditions</Title>
        <div className="contentCover">
          <Paragraph>Last Updated: 28th Jan, 2024</Paragraph>
          <Paragraph>By accessing or using the services provided by Grratz Consulting, you
agree to comply with and be bound by these terms and conditions. If
you do not agree with any part of these terms, please do not use our
services.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>1. Services</Title>
          <Paragraph><span>a. Scope:</span>Grratz Consulting Pvt. Ltd. agrees to provide app
development services as outlined in the agreed-upon project scope.</Paragraph>
<Paragraph><span>b. Changes:</span>Any changes to the project scope must be agreed upon in
writing by both parties.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>2. Payment Terms</Title>
          <Paragraph><span>a. Fees:</span>The client agrees to pay the fees as outlined in the project
proposal or agreement.</Paragraph>
          <Paragraph><span>b. Invoicing:</span>Invoices will be issued according to the agreed-upon
payment schedule.</Paragraph>
          <Paragraph><span>c. Late Payments:</span>Late payments may be subject to interest charges
and may result in a delay in project delivery.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>3. Intellectual Property</Title>
          <Paragraph><span>a. Ownership:</span>Unless otherwise specified, Grratz retains ownership of
all intellectual property developed during the project.</Paragraph>
          <Paragraph><span>b. Client Materials:</span>The client is responsible for providing any
necessary materials and ensuring they have the right to use them.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>4. Confidentiality</Title>
          <Paragraph><span>a. Non-Disclosure:</span> Both parties agree to keep confidential information,
including project details, proprietary and trade secrets, confidential.</Paragraph>
          <Paragraph><span>b. Exceptions:</span>Confidentiality does not apply to information that is
publicly available or rightfully received from a third party.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>5. Limitation of Liability</Title>
          <Paragraph>Grratz Consulting Pvt. Ltd. shall not be liable for any indirect, consequential,
or incidental damages arising out of the use or inability to use our
services.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>6. Termination</Title>
          <Paragraph>Either party may terminate the project if the other party breaches any
material term of these terms and conditions.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>7. Governing Law</Title>
          <Paragraph>These terms and conditions shall be governed by and construed in
accordance with the laws of India.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>8. Changes to Terms</Title>
          <Paragraph>Grratz Consulting Pvt. Ltd. reserves the right to update these terms and
conditions at any time. The client will be notified of any changes.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>Contact Information</Title>
          <Paragraph>If you have any questions or concerns about these terms and
conditions, please contact us at hello@grratz.com</Paragraph>
        </div>
      </Container>
    </AboutListSec>
    <Footer/>
    </>
    
  );
}

export default Terms;
