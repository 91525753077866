import React from "react";
import { withTheme } from "styled-components";

function Logosf(props) {
  const { theme: logoColor } = props;

  return (
    <span className="logo">
      <svg width="124" height="32" viewBox="0 0 124 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="117.333" y="25.3335" width="6.66667" height="6.66667" fill="#F85F2F"/>
        <path d="M0 31.4548V2.09699H10.4658C12.0566 2.09699 13.4939 2.49541 14.7777 3.29227C16.0755 4.08912 17.1012 5.15859 17.8547 6.50066C18.6082 7.84273 18.985 9.31761 18.985 10.9253C18.985 12.5749 18.5943 14.0708 17.8128 15.4128C17.0453 16.7409 16.0127 17.8034 14.715 18.6003C13.4172 19.3831 12.0008 19.7746 10.4658 19.7746H5.10732V31.4548H0ZM5.10732 14.6579H9.92161C10.6472 14.6579 11.3101 14.4762 11.9101 14.1127C12.5102 13.7492 12.9846 13.2599 13.3335 12.6448C13.6963 12.0297 13.8777 11.3447 13.8777 10.5898C13.8777 9.82088 13.6963 9.12888 13.3335 8.51376C12.9846 7.89865 12.5102 7.40935 11.9101 7.04587C11.3101 6.6824 10.6472 6.50066 9.92161 6.50066H5.10732V14.6579Z" fill="white"/>
        <path d="M22.2399 10.4849H27.3472V31.4548H22.2399V10.4849ZM24.8354 7.77982C24.0819 7.77982 23.4469 7.53517 22.9306 7.04587C22.4143 6.5426 22.1562 5.9135 22.1562 5.15859C22.1562 4.41765 22.4143 3.79555 22.9306 3.29227C23.4469 2.78899 24.0749 2.53735 24.8145 2.53735C25.5541 2.53735 26.175 2.78899 26.6774 3.29227C27.1937 3.79555 27.4519 4.41765 27.4519 5.15859C27.4519 5.9135 27.2007 6.5426 26.6983 7.04587C26.196 7.53517 25.575 7.77982 24.8354 7.77982Z" fill="white"/>
        <path d="M30.6011 31.4548L38.2621 20.9279L30.6639 10.464H36.9643L41.4018 16.5662L45.8603 10.464H52.1607L44.5625 20.9279L52.2235 31.4548H45.9231L41.4018 25.2477L36.9015 31.4548H30.6011Z" fill="white"/>
        <path d="M56.4173 31.4548V0H61.5247V31.4548H56.4173Z" fill="white"/>
        <path d="M82.0115 10.4849H87.1189V31.4548H82.0115L81.7813 28.8545C81.2092 29.7912 80.4486 30.5531 79.4997 31.1402C78.5648 31.7134 77.4554 32 76.1716 32C74.6227 32 73.1714 31.7064 71.8178 31.1193C70.4642 30.5321 69.2711 29.7213 68.2385 28.6868C67.2198 27.6523 66.4174 26.457 65.8314 25.1009C65.2592 23.7449 64.9732 22.291 64.9732 20.7392C64.9732 19.2433 65.2453 17.8384 65.7895 16.5242C66.3477 15.2101 67.1221 14.0568 68.1129 13.0642C69.1037 12.0716 70.2479 11.2958 71.5457 10.7366C72.8435 10.1774 74.2389 9.89777 75.732 9.89777C77.1135 9.89777 78.3485 10.2053 79.4369 10.8204C80.5393 11.4356 81.4743 12.2184 82.2418 13.1691L82.0115 10.4849ZM76.046 27.0721C77.1344 27.0721 78.0973 26.7995 78.9346 26.2543C79.7718 25.709 80.4277 24.9751 80.9022 24.0524C81.3766 23.1158 81.6138 22.0883 81.6138 20.9699C81.6138 19.8375 81.3766 18.81 80.9022 17.8873C80.4277 16.9506 79.7649 16.2097 78.9136 15.6645C78.0764 15.1193 77.1205 14.8467 76.046 14.8467C74.9715 14.8467 73.9877 15.1263 73.0946 15.6855C72.2155 16.2307 71.5108 16.9646 70.9805 17.8873C70.4642 18.81 70.2061 19.8375 70.2061 20.9699C70.2061 22.1022 70.4712 23.1298 71.0015 24.0524C71.5317 24.9751 72.2364 25.709 73.1156 26.2543C74.0087 26.7995 74.9855 27.0721 76.046 27.0721Z" fill="white"/>
        <path d="M102.548 9.89777C104.585 9.89777 106.441 10.3941 108.116 11.3866C109.79 12.3792 111.123 13.7143 112.114 15.3919C113.105 17.0555 113.6 18.9008 113.6 20.9279C113.6 22.4517 113.314 23.8847 112.742 25.2267C112.17 26.5688 111.374 27.7501 110.356 28.7706C109.351 29.7772 108.179 30.5671 106.839 31.1402C105.513 31.7134 104.083 32 102.548 32C101.236 32 100.071 31.7064 99.0525 31.1193C98.0478 30.5181 97.2036 29.7422 96.5198 28.7916V31.4548H91.4125V0H96.5198V13.1062C97.2036 12.1555 98.0478 11.3866 99.0525 10.7995C100.071 10.1983 101.236 9.89777 102.548 9.89777ZM102.485 27.0511C103.574 27.0511 104.558 26.7785 105.437 26.2333C106.33 25.6741 107.041 24.9332 107.572 24.0105C108.102 23.0878 108.367 22.0603 108.367 20.9279C108.367 19.8095 108.102 18.789 107.572 17.8663C107.041 16.9297 106.33 16.1887 105.437 15.6435C104.544 15.0983 103.56 14.8257 102.485 14.8257C101.425 14.8257 100.476 15.1053 99.6386 15.6645C98.8013 16.2097 98.1455 16.9506 97.671 17.8873C97.1966 18.81 96.9593 19.8235 96.9593 20.9279C96.9593 22.0603 97.1966 23.0948 97.671 24.0315C98.1455 24.9541 98.8013 25.6881 99.6386 26.2333C100.476 26.7785 101.425 27.0511 102.485 27.0511Z" fill="white"/>
      </svg>
    </span>
  );
}

export default withTheme(Logosf);