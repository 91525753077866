import styled from "styled-components";
import { md, lg } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding:60px 0 130px 0;
  ${lg(`
    padding:50px 0 80px 0;
  `)} 
  ${md(`
    padding:50px 0 50px 0;
  `)} 
  position:relative;
  .latestArticles{
    width:100%;
    &__card{
      .ant-card{
        box-shadow:none;
        .ant-card-cover{
          img{
            border-radius:0;
            width: 100%;
            max-height: 318px;
            min-height: 318px;
            object-fit: cover;
          }
        }
        .ant-card-body{
          padding:0;
          background: #ffffff;
          .ant-card-meta-detail{
            padding-top: 15px;
            .ant-card-meta-title{
              font-size: 14px;
              font-weight: 400;
              color:#000;
            }
            .ant-card-meta-description{
              margin-bottom:30px;
              p{
                font-family: Urbanist, sans-serif;
                font-size: 22px;
                font-weight: 500;
                color:#000;
                margin-bottom:35px;
              }
              a{
                font-size: 14px;
                font-weight: 500;
                color:#000;
              }
            }
          }
        }
        .ant-card-cover{
          overflow: hidden;
          img{
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
            transform-style: preserve-3d;
            transition: all 0.5s ease-in;
            border-radius:0px;
          }
        }
      }
      &:hover .ant-card-cover{
        img{
          transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1);
          transform-style: preserve-3d;
          transition: all 0.5s ease-out;
        }
      }
    }
  }
`;

export const SectionStyle = styled.div`
  width: 100%;
`;

export const CoverStyle = styled.div`
  width: 100%;
`;