import { AboutListSec } from "./Contact.styles";
import ReadyToCreate from "../../Component/ReadyToCreate/ReadyToCreate";
import Footer from "../../Component/Footer/Footer";
import Faq from "../../Component/Faq/Faq";
import ContactUs from "../../Component/ContactUs/ContactUs";
import { useEffect } from "react";

function Contact() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <AboutListSec>
      <ContactUs/>
      <Faq/>
      <ReadyToCreate/>
      <Footer/>
    </AboutListSec>
  );
}

export default Contact;
