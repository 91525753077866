
import { useEffect } from "react";
import { Container } from "../../Component/Container/Container";
import Footer from "../../Component/Footer/Footer";
import { AboutListSec } from "./Policy.styles";
import {Typography } from "antd";
const { Title, Paragraph } = Typography;

function Policy() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
    <AboutListSec>
      <Container>
        <Title level={2}>Grratz Consulting Pvt. Ltd. Privacy Policy</Title>
        <div className="contentCover">
          <Paragraph>Last Updated: 28th Jan, 2024</Paragraph>
          <Paragraph>Thank you for using our services and applications. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>1. Information We Collect</Title>
          <Paragraph><span>a. Information You Provide:</span>We may collect personal information,
such as your name, email address, phone number, and any other
information you provide when contacting us, requesting support, or
using our services.</Paragraph>
<Paragraph><span>b. Automatically Collected Information:</span>We may automatically collect
information about your device, including but not limited to your IP
address, device type, operating system, and browser type.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>2. How We Use Your Information</Title>
          <Paragraph><span>a. Providing Services:</span>We may use your personal information to
provide and improve our services, respond to your inquiries, and fulfill
your requests.</Paragraph>
          <Paragraph><span>b. Analytics:</span>We may use analytics tools to analyze user behavior and
improve our services.</Paragraph>
          <Paragraph><span>c. Marketing and Communications:</span>With your consent, we may send
you promotional materials and updates about our services.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>3. Sharing Your Information</Title>
          <Paragraph><span>a. Third-Party Service Providers:</span>We may share your information with
third-party service providers that assist us in delivering our services.</Paragraph>
          <Paragraph><span>b. Legal Compliance:</span>We may disclose your information to comply with
applicable laws, regulations, or legal processes.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>4. Security</Title>
          <Paragraph>We take reasonable measures to protect your personal information from
unauthorized access, disclosure, alteration, and destruction. However,
no method of transmission over the internet or electronic storage is
100% secure, and we cannot guarantee absolute security.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>5. Your Choices</Title>
          <Paragraph><span>a. Opt-Out:</span>You may opt out of receiving promotional communications
from us by following the instructions in our communications or
contacting us directly.</Paragraph>
          <Paragraph><span>b. Access and Correction:</span>You have the right to access and correct
your personal information. Contact us to request access or corrections.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>6. Changes to this Privacy Policy</Title>
          <Paragraph>We may update this Privacy Policy from time to time. The date of the
latest revision will be indicated at the top of the policy.</Paragraph>
        </div>
        <div className="contentCover">
          <Title level={3}>7. Contact Us</Title>
          <Paragraph>If you have any questions or concerns about this Privacy Policy, please
contact us at hello@grratz.com.</Paragraph>
        </div>
      </Container>
    </AboutListSec>
    <Footer/>
    </>
    
  );
}

export default Policy;
