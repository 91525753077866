import styled from "styled-components";
import { md, lg } from "../../ThemeConfig/Variables";

export const AboutListSec = styled.section`
    width: 100%;
    h2.ant-typography{
        font-family: Urbanist, sans-serif;
        font-size: 48px;
        font-weight: 400;
        color:#000;
        margin:30px 0 50px 0;
        ${md(`
          font-size: 35px;
          margin:30px 0;
      `)}
    }
    .contentLeft{
        width:65%;
        ${md(`
            width:100%;
        `)}
        ${lg(`
            width:100%;
        `)}
        .ant-typography{
            margin:40px 0 0 0;  
        }
        .content{
            margin:30px 0 50px 0;
            font-size: 16px;
            line-height:25px;
            font-weight: 400;
        }
        .list{
            .ant-typography{
                margin:0 0 25px 0;  
            }
            ul{
                margin: 0 0 0 15px;
                padding:0;
                li{
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom:20px;
                }
            }
        }
        .link{
            margin:80px 0 0 0;
        }
    }
`