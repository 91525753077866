import { images } from "../ThemeConfig/images";
export const Constants = {
  siteBanner:{
    heading: 'Digital Excellence, Unified Solutions',
    decription: "Where creativity meets technology. We're your partners in creating exceptional Mobile and web experiences, eye-catching designs, and effective digital strategies",
    link:'/projects',
    linkLabel: 'See Our Work',
    aboutLink:'/about',
    aboutLinkLabel: 'About us',
    image:`${images?.bannerHero}`,
    contctHeading: 'Join Our Growing Network',
    carousel:[
      {
        "id" : "1",
        "count": "14+",
        "label": "Years of Experiences",
        "decription": "Design Innovations"
      },
      {
        "id" : "2",
        "count": "2k+",
        "label": "Happy Customers",
        "decription": "Design Innovation"
      },
      {
        "id" : "3",
        "count": "80+",
        "label": "Completed Project",
        "decription": "Design Innovation"
      }
    ]
  },
  ourTeam:{
    decription:'Our team comprises experts in Mobile apps and web development, UI/UX design, digital marketing and Quality Analysis services ensuring your project is in capable hands. We put our clients at the forefront of everything we do.',
    iconList:[
      {
        "id":"1",
        "image":`${images?.teamOne}`,
      },
      {
        "id":"2",
        "image":`${images?.teamTwo}`,
      },
      {
        "id":"3",
        "image":`${images?.teamThree}`,
      },
      {
        "id":"4",
        "image":`${images?.teamFour}`,
      }
    ]
  },
  selectedWork:{
  title: 'Our Projects',
  subTitle: 'Projects',
  linkText: 'See Our Work',
  link: '/projects',
  workList:[
    {
      "id":"1",
      "image":`${images?.anahrJobsThumb}`,
      "imageLarge":`${images?.anahrjobs}`,
      "altText":`recruitmind`,
      "title":"Recruit Mind",
      "link":"",
      "dicription":""
    },
    {
      "id":"2",
      "image":`${images?.askThumb}`,
      "imageLarge":`${images?.aks}`,
      "altText":`ask`,
      "title":"Space Reader",
      "link":"https://archive-of-spatial-knowledge.org/",
      "dicription":"It is a digital platform and a spatial intervention addressing issues of spatial justice"
    },
    {
      "id":"3",
      "image":`${images?.askAdminThumb}`,
      "imageLarge":`${images?.aksAdmin}`,
      "altText":`aksadmin`,
      "title":"Space Reader Admin",
      "link":"https://archive-of-spatial-knowledge.org/admin/auth-login",
      "dicription":"It is a digital platform and a spatial intervention addressing issues of spatial justice"
    },
    {
      "id":"4",
      "image":`${images?.bidonworkThumb}`,
      "imageLarge":`${images?.bidonwork}`,
      "altText":`bidonwork`,
      "title":"Bid on my Work",
      "link":"",
      "dicription":"The Good Biker is emerging as a premier digital hub for bikers."
    },
    {
      "id":"5",
      "image":`${images?.bloomyThumb}`,
      "imageLarge":`${images?.bloomy}`,
      "altText":`bloomy`,
      "title":"Bloomy technologies",
      "link":"https://www.bloomy-technologies.com/",
      "dicription":"Bloomy Technologies is a leading software development company that creates innovative and cutting-edge solutions for businesses of all sizes."
    },
    {
      "id":"6",
      "image":`${images?.corazonThumb}`,
      "imageLarge":`${images?.corazon}`,
      "altText":`corazonclub`,
      "title":"Corazon Club",
      "link":"https://corazonclub.co/",
      "dicription":""
    },
    {
      "id":"7",
      "image":`${images?.crimewaveThumb}`,
      "imageLarge":`${images?.crimewave}`,
      "altText":`Crimewave`,
      "title":"Crimewave",
      "link":"http://crimewave.tech/#/",
      "dicription":"Crime Wave will help you find crime in your city down to the neighborhood."
    },
    {
      "id":"8",
      "image":`${images?.dabumpThumb}`,
      "imageLarge":`${images?.dabump}`,
      "altText":`dabump`,
      "title":"DaBump",
      "link":"http://dabump.co/",
      "dicription":"Dabump is a social media sports platform that was created with the amateur athletes in mind."
    },
    {
      "id":"9",
      "image":`${images?.dabumpAppThumb}`,
      "imageLarge":`${images?.dabumpApp}`,
      "altText":`dabumpapp`,
      "title":"DaBump App",
      //"link":"/works-description",
      "dicription":"Dabump is a social media sports platform that was created with the amateur athletes in mind."
    },
    {
      "id":"10",
      "image":`${images?.docpalThumb}`,
      "imageLarge":`${images?.docpal}`,
      "altText":`docpal`,
      "title":"Docpal",
      "link":"",
      "dicription":"Consult top doctors online for any health concern"
    },
    {
      "id":"11",
      "image":`${images?.faciThumb}`,
      "imageLarge":`${images?.faci}`,
      "altText":`faci`,
      "title":"FACI",
      "link":"https://faci.ind.in/",
      "dicription":"First Aid Council of India (FACI) is an autonomous body which works to promote First Aid treatment in India"
    },
    {
      "id":"12",
      "image":`${images?.firstholdingThumb}`,
      "imageLarge":`${images?.firstholding}`,
      "altText":`firsthandholding`,
      "title":"First Handholding",
      "link":"https:	//firsthandholding.com/",
      "dicription":"First Hand Holding is India’s most reliable online business service platform, dedicated to helping you in starting, growing."
    },
    {
      "id":"13",
      "image":`${images?.gearexpressThumb}`,
      "imageLarge":`${images?.gearexpress}`,
      "altText":`gearexpress`,
      "title":"Gear Express",
      "link":"https://gearexpress.co/",
      "dicription":"Gear Express are transportation services that provide luxury vehicles, typically stretch limousines, for various purposes."
    },
    {
      "id":"14",
      "image":`${images?.gearThumb}`,
      "imageLarge":`${images?.gear}`,
      "altText":`gear`,
      "title":"Gear Logistics",
      "link":"https://gearlogistics.com.sg/",
      "dicription":"Gear logistics providing the best logistics services in Singapore"
    },
    {
      "id":"15",
      "image":`${images?.gmvssThumb}`,
      "imageLarge":`${images?.gmvss}`,
      "altText":`gmvss`,
      "title":"GMVSS",
      "link":"https://gmvss.ac.in/",
      "dicription":"GMVSS was intended to be a medium of making education easy for students who are unable to attend regular classes."
    },
    {
      "id":"16",
      "image":`${images?.jrLogisticsThumb}`,
      "imageLarge":`${images?.jrLogistics}`,
      "altText":`jrLogistics`,
      "title":"JR Logistics",
      "link":"",
      "dicription":"Logistics company website."
    },
    {
      "id":"17",
      "image":`${images?.juicyThumb}`,
      "imageLarge":`${images?.juicy}`,
      "altText":`juicy`,
      "title":"Juicy Vapes",
      "link":"https://juicyvapes.co/",
      "dicription":"Electronic cigarettes vending machine with touch screens."
    },
    {
      "id":"18",
      "image":`${images?.kinindiaThumb}`,
      "imageLarge":`${images?.kinindia}`,
      "altText":`kinindia`,
      "title":"Kinindia",
      "link":"https://kinindia.co/",
      "dicription":"Kin primarily focuses on Mega Exhibitions, Large scale Events/Product launches, Live Events and Branding."
    },
    {
      "id":"19",
      "image":`${images?.koliThumb}`,
      "imageLarge":`${images?.koli}`,
      "altText":`koli`,
      "title":"koli",
      "link":"https://www.koli.ai/#/",
      "dicription":"An influencer marketplace."
    },
    {
      "id":"20",
      "image":`${images?.koliAppThumb}`,
      "imageLarge":`${images?.koliApp}`,
      "altText":`koliapp`,
      "title":"koliApp",
      "link":"https://play.google.com/store/apps/details?id=co.koli.android&hl=en_IN&gl=US",
      "dicription":"An influencer marketplace app."
    },
    {
      "id":"21",
      "image":`${images?.loanbuddyThumb}`,
      "imageLarge":`${images?.loanbuddy}`,
      "altText":`loanbuddy`,
      "title":"LoanBuddy",
      "link":"https://loanbuddy.co.in/",
      "dicription":"Get the funds you need now - Apply for a loan and seize opportunities today."
    },
    {
      "id":"22",
      "image":`${images?.loanbuddyAdminThumb}`,
      "imageLarge":`${images?.loanbuddyAdmin}`,
      "altText":`loanbuddyadmin`,
      "title":"LoanBuddy Admin",
      "link":"https://loanbuddy.co.in/Admin/",
      "dicription":"Get the funds you need now - Apply for a loan and seize opportunities today."
    },
    {
      "id":"23",
      "image":`${images?.logisticsllpThumb}`,
      "imageLarge":`${images?.logisticsllp}`,
      "altText":`logisticsllp`,
      "title":"Logisticsllp",
      "link":"",
      "dicription":""
    },
    {
      "id":"24",
      "image":`${images?.maxarbitrageThumb}`,
      "imageLarge":`${images?.maxarbitrage}`,
      "altText":`maxarbitrage`,
      "title":"Max Arbitrage",
      "link":"https://maxarbitrage.com/",
      "dicription":"MAL is an independent, employee-owned firm marketed approximately $100 million in funds across different arbitrage-driven strategies"
    },
    {
      "id":"25",
      "image":`${images?.otiumThumb}`,
      "imageLarge":`${images?.otium}`,
      "altText":`otium`,
      "title":"Otium",
      "link":"https://otium.co.in/",
      "dicription":"Online furniture store."
    },
    {
      "id":"26",
      "image":`${images?.poshanThumb}`,
      "imageLarge":`${images?.poshan}`,
      "altText":`poshan`,
      "title":"Poshan",
      "link":"https://poshn.co/",
      "dicription":"Processed Agriculture Commodity Trading Made Easy"
    },
    {
      "id":"27",
      "image":`${images?.propeaseThumb}`,
      "imageLarge":`${images?.propease}`,
      "altText":`propease`,
      "title":"Propease",
      "link":"",
      "dicription":"A property management platform"
    },
    {
      "id":"28",
      "image":`${images?.recruitmindThumb}`,
      "imageLarge":`${images?.recruitmind}`,
      "altText":`recruitmind`,
      "title":"Recruit Mind",
      "link":"",
      "dicription":"Recruitment assistance company"
    },
    {
      "id":"29",
      "image":`${images?.saveonclickThumb}`,
      "imageLarge":`${images?.saveonclick}`,
      "altText":`saveonclick`,
      "title":"Saveonclick",
      "link":"",
      "dicription":"Ecommerce platform to buy groceries online."
    },
    {
      "id":"30",
      "image":`${images?.spheriumThumb}`,
      "imageLarge":`${images?.spherium}`,
      "altText":`spherium`,
      "title":"Spherium",
      "link":"https://spherium.finance/#/",
      "dicription":"A safe, fast and secure way to bridge assets cross-chain in an instant"
    },
    {
      "id":"31",
      "image":`${images?.tgbThumb}`,
      "imageLarge":`${images?.tgb}`,
      "altText":`tgb`,
      "title":"The Good Biker",
      "link":" https://www.thegoodbiker.com/",
      "dicription":"The Good Biker is emerging as a premier digital hub for bikers."
    },
    {
      "id":"32",
      "image":`${images?.tgbAdminThumb}`,
      "imageLarge":`${images?.tgbAdmin}`,
      "altText":`tgbadmin`,
      "title":"The Good Biker Admin",
      "link":"",
      "dicription":"The Good Biker is emerging as a premier digital hub for bikers."
    },
    {
      "id":"33",
      "image":`${images?.ultimaThumb}`,
      "imageLarge":`${images?.ultima}`,
      "altText":`ultima`,
      "title":"Ultima",
      "link":"",
      "dicription":"Horses races and buying platform."
    },
    {
      "id":"35",
      "image":`${images?.uniqstopThumb}`,
      "imageLarge":`${images?.uniqstop}`,
      "altText":`uniqstop`,
      "title":"Uniqstop",
      "link":"https://uniqstop.com/",
      "dicription":"Online shopping platform."
    },
    {
      "id":"36",
      "image":`${images?.vivsunengThumb}`,
      "imageLarge":`${images?.vivsuneng}`,
      "altText":`vivsunen`,
      "title":"Vivsunen",
      "link":"https://www.vivsuneng.com/",
      "dicription":"Vivsun, manufactures in their state-of-the-art facility spread over 50,000 square feet  using 100% clean solar energy"
    },
    {
      "id":"37",
      "image":`${images?.washtonThumb}`,
      "imageLarge":`${images?.washton}`,
      "altText":`washton`,
      "title":"Washton",
      "link":"https://washton.in/",
      "dicription":"We collect, clean, and deliver your laundry and dry cleaning."
    },
    {
      "id":"38",
      "image":`${images?.wedoveThumb}`,
      "imageLarge":`${images?.wedove}`,
      "altText":`wedove`,
      "title":"WEdove",
      "link":"http://wedove.com/#/",
      "dicription":"Looking for Omnichannel, Bots, and Self-service solutions for customer service? We've got you covered!"
    }
  ],
},
  whoWeAre:{
  title: 'Our Mission',
  subTitle: 'Mission',
  linkText: 'About Us', 
  link: '/about',
  dicription: 'At Grratz, our mission is to empower businesses and individuals by providing cutting-edge solutions that propel them to the forefront of the digital landscape. We believe in crafting experiences that not only meet but exceed expectations, fostering lasting connections between brands and their audiences.',
  whoWeList:[
    {
      "id" : "1",
      "count": "14+",
      "label": "Years of Experiences"
    },
    {
      "id" : "2",
      "count": "80k+",
      "label": "Completed Project"
    },
    {
      "id" : "3",
      "count": "2k+",
      "label": "Happy Customers"
    }
  ],
},

ourServiceList:{
  title: 'Our Services',
  subTitle: 'What we do',
  linkText: 'View All Services', 
  link: '/services',
serviceList:[
  {
    "id":"1",
    "image":`${images?.appDevelopmentservice}`,
    "title":"App Development",
    "serviceLink":'App',
    "link":"/app",
    "dicription":"Ready to transform your app ideas into reality? Get in touch with us today to schedule a consultation and discover how Grratz can help you achieve your app development goals. Let's embark on this journey together and create something extraordinary!",
    "icon":`${images?.iconDiamond}`,
    "topService":"Top Service"
   },
  {
    "id":"2",
    "image":`${images?.webDevelopment}`,
    "title":"Web Development",
    "serviceLink":'Web',
    "link":"/web",
    "dicription":"At Grratz, we're passionate about crafting exceptional websites that not only meet but exceed our clients' expectations. With a dedicated team of skilled developers, designers, and digital strategists, we're committed to delivering customized web solutions that drive results and elevate your online presence. Whether you're a small business looking to establish your brand or a large corporation seeking to optimize your digital strategy, we're here to help you achieve your goals and stand out in today's competitive landscape."
  },
  {
    "id":"3",
    "image":`${images?.digitalMarketting}`,
    "title":"Digital Marketing",
    "serviceLink":'Marketing',
    "link":"/marketing",
    "dicription":"At Grratz, we specialize in driving online growth and maximizing digital success for businesses of all sizes. With our comprehensive suite of digital marketing services and a team of experienced professionals, we're dedicated to helping you reach your target audience, increase brand visibility, and generate leads and conversions. Whether you're a startup looking to establish your presence or an established enterprise aiming to expand your digital footprint, we're here to partner with you and achieve your marketing objectives"
  },
  {
    "id":"4",
    "image":`${images?.uXDesign}`,
    "title":"UI/UX Designing",
    "serviceLink":'Designing',
    "link":"/designing",
    "dicription":"At Grratz, we specialize in creating captivating and intuitive user interfaces (UI) and user experiences (UX) that delight users and drive business growth. With a focus on user-centered design principles and cutting-edge technology, we're dedicated to crafting digital experiences that engage, inspire, and leave a lasting impression. Whether you're launching a new product, redesigning an existing platform, or seeking to optimize your user journey, we're here to bring your vision to life and exceed your expectations."
  },
  {
    "id":"5",
    "image":`${images?.analytics}`,
    "title":"Analytics & Reporting",
    "serviceLink":'Analytics',
    "link":"/analytics",
    "dicription":"Unlock the power of data-driven decision-making with Grratz's Analytics and Reporting Service. We offer comprehensive solutions to help businesses of all sizes harness the full potential of their data, gain valuable insights, and drive strategic growth."
  },
  {
    "id":"6",
    "image":`${images?.consultationStrategy}`,
    "title":"Consultation & Strategy",
    "serviceLink":'Consultation',
    "link":"/consultation",
    "dicription":"At Grratz, we understand that navigating the complexities of business can be challenging. That's why we offer tailored consultation and strategy services to help businesses like yours overcome obstacles, capitalize on opportunities, and achieve sustainable growth. With our team of seasoned experts and proven methodologies, we're committed to providing you with the insights, strategies, and support you need to thrive in today's competitive landscape."
  },
  ]
},

latestArticles:{
  title: 'Latest Articless',
  subTitle: 'News',
  moreArticles: 'More Articles',
  moreArticlesLink: '',
  image:`${images?.blogThumbHero}`,
  imageDate:'Oct 10, 2023',
  imageTitle:"The power of branding Grratz's guide to building a memorable identity",
  link:"/blogs",
  articlesList:[
    {
      "id":"1",
      "image":`${images?.eCommerce}`,
      "date": "October 10, 2023",
      "title":"Unveiling the Future of E-Commerce",
      "articalLink":"Future Blog",
      "linkTitle":"/futureBlog",
    },
    {
      "id":"2",
      "image":`${images?.appDevelopment}`,
      "date": "October 10, 2023",
      "title":"Mobile App Development",
      "articalLink":"Mobile Blog",
      "linkTitle":"/mobile",
    }
  ]
},

clientSays:{
  title: 'What Our Clients Say',
  subTitle: 'Testimonial',
  carouselList:[
    {
      "id":"1",
      "image":`${images?.larry}`,
      "dicription":`"Working with Grratz Consulting Pvt Ltd was a game-changer for our business. Their app development team exceeded our expectations in terms of creativity, functionality, and on-time delivery. Our new app has significantly improved user engagement, and we've seen a noticeable boost in our bottom line. I highly recommend Grratz Consulting Pvt. Ltd. for anyone looking to elevate their digital presence."`,
      "name":"Larry",
      "designation":" Founder of Manico Games LLC",
    },
    {
      "id":"2",
      "image":`${images?.renato}`,
      "dicription": `"I can't express how pleased I am with the app developed by Grratz. They took our concept and turned it into a sleek, user-friendly application. The attention to detail and the seamless integration of features have made our app a hit among users. The communication throughout the development process was excellent, and I appreciate the dedication of the entire team at Grratz."`,
      "name":"Renato",
      "designation":"CEO of Skolen",
    },
    {
      "id":"3",
      "image":`${images?.prateek}`,
      "dicription": `"Choosing Grratz Consulting for our app development needs was one of the best decisions we made. Their expertise in both design and functionality resulted in an app that perfectly aligns with our brand and meets the expectations of our diverse user base. The post-launch support and updates have been seamless, making Grratz Consulting our go-to partner for any future digital projects."`,
      "name":"Prateek",
      "designation":"COO of TGB Technologies Pvt Ltd",
    },
    {
      "id":"5",
      "image":`${images?.luv}`,
      "dicription": `"As a startup founder, I was looking for a reliable partner to bring my app idea to life. Grratz Consulting not only transformed my vision into reality but also provided strategic guidance that contributed to the success of our launch. The team's dedication, creativity, and technical expertise make them the go-to choice for any entrepreneur looking to disrupt the market with a cutting-edge app."`,
      "name":"Luv",
      "designation":"Director of LoanBuddy",
    }
  ]
},

readyToCreate:{
  title: 'Ready to Create Something Extraordinary?',
  subTitle: "Let's work together to create a winning strategy tailored to your needs.",
  icon:`${images?.readyToCreateIcon}`,
  image:`${images?.readyToCreate}`,
  linkText: 'Get Started', 
  link: '/',
},

//About
aboutUs:{
  title: 'About us',
  dicription: "Whether you are looking to launch a new website, revamp your existing website, develop a mobile app, or enhance your overall digital strategy, we are here to help.",
  imageHero:`${images?.aboutHero}`,
  imageRight:`${images?.aboutInfo}`
},

//How we work
howWeWork:{
  title: 'How We Work',
  subTitle: 'Process',
  imageHero:`${images?.howWeWork}`,
  dicription: "Our approach to work is guided by a commitment to excellence, innovation, and collaboration. We believe that the way we work defines the quality of our outcomes and the impact we make.",
  linkText: 'Our Work', 
  link: '/works',
  workList:[
    {
      "id":"1",
      "dicription":"Understanding Your Needs"
    },
    {
      "id":"2",
      "dicription":"Creative Ideation"
    },
    {
      "id":"3",
      "dicription":"Collaborative Planning"
    },
    {
      "id":"4",
      "dicription":"Continuous Communication"
    },
    {
      "id":"5",
      "dicription":"Quality Control"
    },
    {
      "id":"6",
      "dicription":"Constant Support"
    }
  ]
},

//Counts
workList:[
  {
    "id":"1",
    "count" : "80+",
    "dicription":"Projects Completed"
  },
  {
    "id":"2",
    "count" : "14+",
    "dicription":"Years of Experience"
  },
  {
    "id":"3",
    "count" : "2k",
    "dicription":"Happy Customers"
  },
  {
    "id":"4",
    "count" : "30+",
    "dicription":"In-house Experts"
  }
],

//Meet our team
meetOurTeam:{
  title: 'Meet Our Team',
  subTitle: 'Members',
  memberList:[
    {
      "id":"1",
      "image":`${images?.amitPrajapat}`,
      "title":"Amit Prajapat",
      "designation":"CEO",
    },
    {
      "id":"2",
      "image":`${images?.avinashKumar}`,
      "title":"Avinash Kumar",
      "designation":"Delivery Head",
    },
    {
      "id":"3",
      "image":`${images?.jayeshMalhotra}`,
      "title":"Jayesh Malhotra",
      "designation":"Client Relationship Manager",
    },
    {
      "id":"4",
      "image":`${images?.soniaArora}`,
      "title":"Soniya Arora",
      "designation":"Business Development Head",
    }
  ]
},

//FAQ
askQuetion:{
  title: 'Frequently Asked Questions',
  subTitle: "FAQ's",
},

//Informative Articles
informativeArticles:{
  imageHero:`${images?.blogThumbHero}`,
  date:'Oct 10, 2023',
  dicription: "The power of branding Grratz's guide to building a memorable identity",
  linkText: "Read More",
  linkTitle:"/blog-description",
},

//Blog
blogList:[
  {
    "id":"1",
    "image":`${images?.eCommerceThumb}`,
    "date": "October 10, 2023",
    "title":"Unveiling the Future of E-Commerce",
    "articalLink":"Read More",
    "linkTitle":"/unveiling-futureBlog",
  },
  {
    "id":"2", 
    "image":`${images?.appDevelopThumb}`,
    "date": "April 17, 2023",
    "title":"Mobile App Development",
    "articalLink":"Read More",
    "linkTitle":"/mobile-app-development",
  },
  {
    "id":"3",
    "image":`${images?.digitalMarkettingThumb}`,
    "date": "January 10, 2023",
    "title":"Power of Story telling in marketing",
    "articalLink":"Read More",
    "linkTitle":"/story-telling",
  },
  {
    "id":"4",
    "image":`${images?.uiuxDesignThumb}`,
    "date": "October 20, 2022",
    "title":"Amazing UI UX Designs",
    "articalLink":"Read More",
    "linkTitle":"/ui-designs",
  }
]

}

