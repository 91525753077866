import styled from "styled-components";
import { md, lg } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  display:flex;
  padding: 80px 0 150px 0;
  align-items: center;
  ${lg(`
    padding: 30px 0 80px 0;
  `)}
  ${md(`
    padding: 30px 0;
  `)}
 
`;

export const SectionStyle = styled.div`
  width: 100%;
  p{
    color: #333;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
    padding: 0px 45px;
    ${lg(`
      font-size: 22px;
      line-height: 38px;
      text-align: left;
      padding:0 15px;
  `)}
    ${md(`
      padding: 0;
      font-size: 20px;
      line-height: 35px;
      text-align: left;
    `)}
  }
`;
export const ListStyle = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
margin:40px 0;
${md(`
    flex-wrap: wrap;
    margin:25px 0;
`)}
img{
  margin:0 27px;
  ${md(`
    margin:0;
`)}
}

`;

export const LogoStyle = styled.div`
display:flex;
align-items: center;
justify-content: center;
${md(`
    width:48%;
    margin-bottom: 25px;
`)}
`;

export const CoverStyle = styled.div`
  width:100%;
`;