import { useState, useEffect } from "react";

const useScrolling = (scrollThreshold = 20) => {
  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setScrolled(scrollTop > scrollThreshold);
  };

    useEffect(() => {
       
        //Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        //Clean up the event listener when the component unmount
        return () => window.removeEventListener('scroll', handleScroll)

    },[]);

    return isScrolled
}

export default useScrolling;