import { images } from "../../ThemeConfig/images";
import { WrapperStyle, CoverStyle } from "./Faq.styles";
import { Container } from "../Container/Container";
import { Col, Row, Tag, Flex, Collapse } from "antd";
import useMediaQuery from '../../Hooks/useMediaQuery';
import { FaqAdd, FaqRemove } from "../Icon";
import { Constants } from "../../db/Constants";

const items = [
  {
    key: '1',
    label: 'What services does Grratz offer?',
    children: <p>From web and app development, ensuring responsive and seamless user experiences, to digital marketing strategies spanning social media, search engines, email, and content, Grratz drives brand awareness and engagement.</p>,
  },
  {
    key: '2',
    label: 'How long does it take to complete a web development project?',
    children: <p>The timeline for a web development project varies based on its complexity and your specific requirements. We'll provide you with a project timeline during our initial consultation.</p>,
  },
  {
    key: '3',
    label: 'Can you help with social media marketing?',
    children: <p>Absolutely! Grratz offers comprehensive social media marketing services designed to boost your online presence and engage your target audience effectively. With expertise across various platforms, including Facebook, Instagram, Twitter, and LinkedIn.</p>,
  },
  {
    key: '4',
    label: 'Do you provide ongoing support after a project is completed?',
    children: <p>Certainly! At Grratz, our commitment to client satisfaction extends beyond project completion. We offer comprehensive ongoing support to ensure your continued success. Whether you have questions, need technical assistance.</p>,
  },
  {
    key: '5',
    label: 'How can I schedule a consultation with your team?',
    children: <p>Scheduling a consultation with our team at Grratz is simple and convenient. Visit our "Contact Us" page on the website, where you'll find various contact options.</p>,
  },
  {
    key: '6',
    label: 'What payment methods do you accept?',
    children: <p>Grratz accepts a variety of payment methods to provide flexibility for our clients. We currently accept payments through major credit and debit cards, including Visa, Mastercard, American Express, and Discover.</p>,
  },
];

const Faq = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperOdd" : "webLayout wrapperOdd";
  console.log(props.themevalue);
  return (
    <WrapperStyle className={layoutClass}>
      <Container>
        <CoverStyle>
        <Tag>{Constants?.askQuetion?.subTitle}</Tag>
        <h2>{Constants?.askQuetion?.title}</h2>
        <Row gutter={[16, 16]}>
          <Flex wrap="wrap" justify={"space-between"} align={"center"} className="faqWrapper">
            <Col xs={24} md={12} lg={12}>
              <div className="faqWrapper__img">
                <img alt="faqImg" src={images.faqs} className="imgResponsive"/>
              </div> 
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="faqWrapper__info">
              <Collapse 
              className="collapse"
                accordion items={items} 
                bordered={false} 
                expandIcon={({ isActive }) => (isActive ? <FaqRemove/> : <FaqAdd/>)}
              />
              </div>
            </Col>
          </Flex>
        </Row>
        </CoverStyle>
      </Container>
    </WrapperStyle>
  );
};

export default Faq;
